.color--red {
  color: $color-red-600;
}

a.color--red:hover {
  color: $color-red-700;
}

.color--blue {
  color: $color-blue-600;
}

a.color--blue:hover {
  color: $color-blue-700;
}

.color--black {
  color: $color-gray-900;
}

a.color--black:hover {
  color: $color-gray-800;
}

.primary {
  color: $app-highlight-color !important;

  &.circled,
  &.rounded,
  &.filled {
    background-color: $app-highlight-color !important;
    color: $app-highlight-text-color !important;
  }
}

.warning,
.orange {
  color: $app-color-warning !important;

  &.circled,
  &.rounded,
  &.filled {
    background-color: $app-color-warning !important;
    color: $app-header-color-highlight !important;
  }
}

.danger,
.red {
  color: $app-color-danger !important;

  &.circled,
  &.rounded,
  &.filled {
    background-color: $app-color-danger !important;
    color: $app-header-color-highlight !important;
  }
}

.normal,
.blue {
  color: $app-color-normal !important;

  &.circled,
  &.rounded,
  &.filled {
    background-color: $app-color-normal !important;
    color: $app-header-color-highlight !important;
  }
}

.black {
  color: $app-color-black !important;

  &.circled,
  &.rounded,
  &.filled {
    background-color: $app-color-black !important;
    color: $app-header-color-highlight !important;
  }
}

.success,
.green {
  color: $app-color-success !important;

  &.circled,
  &.rounded,
  &.filled {
    background-color: $app-color-success !important;
    color: $app-header-color-highlight !important;
  }
}

.info,
.cyan {
  color: $app-color-info !important;

  &.circled,
  &.rounded,
  &.filled {
    background-color: $app-color-info !important;
    color: $app-header-color-highlight !important;
  }
}

hr {
  display: block;
  height: 1px;
  margin: 1rem 0;
  border: 0;
  background: fade-out($app-color-black, 0.78);
}

ul {
  li {
    line-height: 160%;
  }
}

.check-privilege-and-render {
  .fx-guard-error {
    &:before {
      display: none !important;
    }
  }
}