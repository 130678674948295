.al-list {
  position: relative;
  overflow: hidden;
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .al-list-wrapper {
    position: relative;

    .al-list-item {
      @include forLaptop("max-height", calc(#{$font-size-base} * 2.28));
      position: relative;
      overflow: hidden;
      max-height: calc(#{$font-size-base} * 1.15);
      margin-top: 0.3rem;
      padding-left: calc(#{$font-size-base} * 1.7);
      font-size: $font-size-base;
      line-height: 120%;
      text-overflow: ellipsis;

      a {
        @include forLaptop("-webkit-line-clamp", 2);
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      &:first-child {
        margin-top: 0;
      }

      &:before {
        content: "";
        position: absolute;
        top: calc(#{$font-size-base} / 2);
        left: calc(#{$font-size-base} / 1.5);
        display: block;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: $app-color-black;
      }
    }
  }
}

.al-card {
  .al-list {
    .al-list-wrapper {
      padding-right: 1.45rem;

      .al-list-item {
      }
    }
  }
}
