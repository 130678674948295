@import "variables.scss";

$-domain-height: 50px;
$-folder-width: 220px;
$-property-width: 300px;
$-section-spacing: 0.5rem;
$-element-padding: 0.5rem;
$-element-spacing: 0.2rem;
$-element-border-color: rgba(#d9d9d9, 0.5);
$-element-border-color-hover: #bfbfbf;
$-element-border-color-selected: #aaa;
$-element-border-color-selected-hover: #888;
$-element-border-color-active: #999;

.app-builder-study-add-wrapper {
  .title-add-button {
    > div.ms-TooltipHost {
      width: 100%;

      .ms-Button--icon {
        width: 100%;
      }
    }
  }
}

.app-builder-crf-build-entry-screen {
  display: flex;
  word-break: keep-all;

  .app-builder-crf-build-entry-screen-conf,
  .app-builder-crf-build-entry-screen-property {
    flex-basis: 100%;
  }

  .app-builder-crf-build-entry-screen-conf {
    max-width: calc(
      100vw - #{$app-sidebar-width-collapsed} - #{$app-content-padding} - #{$app-content-padding} - #{$-section-spacing} - #{$-section-spacing} -
      #{$-property-width}
    );
  }

  .app-builder-crf-build-entry-screen-property {
    overflow-x: hidden;
    overflow-y: auto;
    width: $-property-width;
    min-width: $-property-width;
    max-width: calc(#{$-property-width} * 1.2);
    height: calc(100vh - #{$app-header-height} - 7.8rem);
    margin-left: $-section-spacing;
    padding: 0;
    border-radius: $app-form-border-radius;
    background: $app-form-background;
    box-shadow: $app-content-box-shadow;

    > div:not(:first-child) {
      margin-top: 1.5rem;
    }

    > div > strong {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
      padding: #{$-element-padding} calc(#{$-element-padding} * 2);
      background: #eaeaea;
      font-weight: bold;
      font-size: $font-size-sm;
      text-transform: uppercase;

      &:first-child {
        margin-top: 0;
      }

      button {
        display: flex;
        align-items: center;
        padding: 0.2rem 0.6rem;
        border: none;
        border: 1px solid $-element-border-color-hover;
        border-radius: $app-form-border-radius;
        font-size: $font-size-sm;
        cursor: pointer;

        &:hover {
          border-color: $-element-border-color-selected-hover;
        }

        i {
          margin-right: 0.3rem;
        }
      }
    }

    .app-builder-crf-build-entry-screen-property-title {
    }

    .app-builder-crf-build-entry-screen-crf-property-domain,
    .app-builder-crf-build-entry-screen-crf-property-page,
    .app-builder-crf-build-entry-screen-crf-property-section,
    .app-builder-crf-build-entry-screen-crf-property-item,
    .app-builder-crf-build-entry-screen-crf-property-triggers {
      margin: 0;
      padding: calc((#{$-element-padding} * 2) - 0.5rem) calc(#{$-element-padding} * 2);
      list-style: none;

      .ms-layer {
        margin: 0;
      }

      li {
        padding: 0.5rem 0;
        border-bottom: 1px solid $-element-border-color;
        line-height: 140%;

        &:last-child {
          border-bottom: none;
        }

        > strong {
          display: flex;
          justify-content: space-between;

          strong {
            display: flex;
            flex-basis: 100%;
            align-items: stretch;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        p {
          flex-shrink: 0;
          margin: 0;
          padding: 0;
        }

        .--type {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          overflow: hidden;
          min-height: 19px;
          margin-right: 0;
          padding: 1px 0.3rem 0 0.4rem;
          border: none;
          border-radius: $app-form-border-radius;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          background: #808080;
          color: #fff;
          box-shadow: $app-content-box-shadow;
          font-weight: bold;
          font-style: normal;
          font-size: $font-size-xs;
          text-transform: uppercase;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
        }

        .--id {
          display: flex;
          align-items: center;
          overflow: hidden;
          min-height: 19px;
          margin-right: 0;
          padding: 1px 0.3rem 0 0.4rem;
          border: none;
          border-radius: $app-form-border-radius;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          background: #302929;
          color: #fff;
          box-shadow: $app-content-box-shadow;
          font-weight: normal;
          font-style: normal;
          font-size: $font-size-xs;
          text-transform: uppercase;
          word-break: break-all;

          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .--note {
          display: block;
          margin-top: 0.2rem;
          word-break: break-all;
        }

        &.--type-status .--type {
          background-color: $color-primary;
        }
        &.--type-assign .--type {
          background-color: $app-color-danger;
        }
        &.--type-query .--type {
          background-color: $app-color-normal;
        }
        &.--type-e-sign .--type {
          background-color: $app-color-warning;
        }
        &.--type-medical-term .--type {
          background-color: $app-color-success;
        }
        &.--type-medication .--type {
          background-color: $app-color-success;
        }
        &.--type-v1 .--type {
          background-color: $app-color-black;
        }
      }
    }
  }

  .app-builder-crf-build-entry-screen-crf-property-triggers {
    .app-builder-crf-build-misc-buttons {
      button:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      button:not(:first-child) {
        margin-left: -1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .--icon-close {
        margin-right: 0;
      }
    }
  }

  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  *::-webkit-scrollbar-track {
    border-radius: $app-form-border-radius;
    background-color: #e4e4e4;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: $app-form-border-radius;
    background-color: #999;
  }

  .--empty {
    padding-top: 20vh;
    padding-bottom: 5vh;
    font-size: $font-size-sm;
    text-align: center;

    &:before {
      content: "crop_free";
      display: block;
      width: 100%;
      height: 100%;
      padding-bottom: 1rem;
      font-size: 5rem;
      font-family: "Material Icons Outlined";
      text-align: center;
    }

    &.--tiny {
      padding: 1rem 0;

      &:before {
        display: none;
      }
    }
  }

  .app-builder-crf-build-misc-buttons {
    display: flex;
    justify-content: flex-end;
    margin-left: 0.5rem;

    > button {
      display: flex;
      align-items: center;
      height: auto;
      margin-left: 0.3rem;
      padding: 0.4rem 0.5rem;
      border: 1px solid $-element-border-color-hover;
      border-radius: $app-form-border-radius;
      background: darken($app-form-background, 2%);
      box-shadow: $app-content-box-shadow;
      outline: none;
      font-size: $font-size-sm;
      line-height: 100%;
      cursor: pointer;

      &.cr-contextual-menu {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;

        i.ms-Button-menuIcon {
          height: auto;
          margin-top: -1px;
          margin-right: 0;
          margin-left: 0.3rem;
          font-size: $font-size-xs;
          line-height: 100%;
        }
      }

      button {
        display: flex;
        align-items: center;
        height: auto;
        margin: 0;
        padding: 0;
        border: 0;
        background: transparent;
        font-size: $font-size-sm;
        line-height: 100%;
        cursor: pointer;
        appearance: none;
      }

      > i,
      span > i {
        margin-right: 0.35rem;
        margin-bottom: -1px;
        font-size: $font-size-base;
      }

      &.icon {
        i {
          margin-right: 0;
        }
      }

      &.is-expanded {
        color: #000;
      }

      &:first-child {
        margin-right: 0;
      }

      &:disabled {
        cursor: default;
      }

      &:hover:not(:disabled) {
        border-color: $-element-border-color-selected;
        color: #000;
      }

      &:active:not(:disabled) {
        border-color: $-element-border-color-active;
        background: darken($app-form-background, 4%);
      }
    }
  }
}

@media (max-width: 700px) {
  .app-builder-crf-build-entry-screen {
    display: block;

    .app-builder-crf-build-entry-screen-conf {
      max-width: 100%;
    }
    .app-builder-crf-build-entry-screen-property {
      display: none;
    }
  }
}

.app-builder-crf-build-entry-screen-wrapper {
  $-domain-scrollbar-height: 3px;

  display: flex;
  flex-direction: column;

  .app-builder-crf-build-entry-screen-domain {
    .app-builder-crf-build-entry-screen-domains-wrapper {
      border-radius: $app-form-border-radius;
      background: $app-form-background;
      box-shadow: $app-content-box-shadow;
    }

    ul.app-builder-crf-build-entry-screen-domains {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      margin: 0;
      padding: 0;
      list-style: none;

      &::-webkit-scrollbar {
        /* TODO. Scrollbar 두께를 얇게 했기에, Scrollbar 대신 <, > 버튼을 두어 스크롤 할 수 있도록 처리해야함 */
        height: $-domain-scrollbar-height;
      }

      li {
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: $-domain-height;
        margin: $-element-padding $-element-spacing;
        border: 1px solid $-element-border-color;
        border-radius: $app-form-border-radius;
        background: $app-form-background;
        box-shadow: $app-content-box-shadow;
        font-weight: bold;
        font-size: $font-size-sm;

        &.--add {
          border-color: darken($-element-border-color, 5%);
          background: darken($app-form-background, 2%);

          span {
          }
        }

        &:hover {
          border-color: $-element-border-color-hover;
          background-color: #fbfbfb;
          cursor: pointer;

          &.--selected {
            border-color: $-element-border-color-selected-hover;
          }
        }

        &.--selected {
          border-color: $-element-border-color-selected;
        }

        span {
          overflow: hidden;
          width: 100%;
          padding: 0 0.4rem;
          font-weight: normal;
          font-size: $font-size-xs;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .cr-icon {
          margin-top: 3px;
          margin-bottom: 2px;
          font-size: $font-size-2xl;
        }

        &:first-child {
          margin-left: 0.5rem;
        }

        &:last-child {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .app-builder-crf-build-entry-screen-section {
    display: flex;
    margin-top: $-section-spacing;
    margin-right: calc(#{$-section-spacing} / -2);
    margin-left: calc(#{$-section-spacing} / -2);

    .json--debugger {
      position: relative;
      padding: 1rem;
      border-radius: 0.45rem;
      background: darken(red, 25%);
      color: #fff;
      font-size: $font-size-xs;

      pre {
        margin: 0;
        padding: 0;
      }
    }

    .app-builder-crf-build-entry-screen-folder,
    .app-builder-crf-build-entry-screen-crf {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      height: calc(100vh - #{$app-header-height} - #{$-domain-height} - #{$-section-spacing} - #{$-domain-scrollbar-height} - 8.8rem);
      min-height: 10rem;
      margin: 0 calc(#{$-section-spacing} / 2);
      border-radius: $app-form-border-radius;
      background: $app-form-background;
      box-shadow: $app-content-box-shadow;
    }

    .app-builder-crf-build-entry-screen-folder {
      flex-basis: 50%;
      min-width: calc(#{$-folder-width} * 0.7);
      max-width: $-folder-width;

      .app-builder-crf-build-entry-screen-folders-wrapper {
      }

      ul.app-builder-crf-build-entry-screen-folders {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          display: flex;
          flex-direction: column;
          margin: $-element-spacing $-element-padding;
          padding: $-element-padding;
          border: 1px solid $-element-border-color;
          border-radius: $app-form-border-radius;
          background: $app-form-background;
          box-shadow: $app-content-box-shadow;

          &.--selected {
            border-color: $-element-border-color-selected;
          }

          span {
            display: flex;
            align-items: center;
            justify-content: space-between;
            overflow: hidden;
            padding: 0 0.4rem;
            font-weight: bold;
            font-size: $font-size-sm;
            text-overflow: ellipsis;
            white-space: nowrap;

            > span {
              display: block;
              padding: 0;
            }

            em {
              overflow: hidden;
              margin-left: 0.4rem;
              color: $color-gray-600;
              font-weight: normal;
              font-style: normal;
              font-size: $font-size-xs;
              line-height: 100%;
              text-align: right;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          &:first-child {
            margin-top: $-element-padding;
          }

          &:last-child {
            margin-bottom: $-element-padding;
          }
        }
      }

      ul.app-builder-crf-build-entry-screen-folder-visits {
        flex-basis: 100%;
        margin: 0;
        padding: 0;

        li {
          margin-right: 0;
          margin-left: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      ul.app-builder-crf-build-entry-screen-folder-visit-crfs {
        flex-basis: 100%;
        margin: 0;
        padding: 0;

        li {
          padding: calc(#{$-element-padding} * 0.4);

          &:hover {
            border-color: $-element-border-color-hover;
            background-color: #fbfbfb;
            cursor: pointer;

            &.--selected {
              border-color: $-element-border-color-selected-hover;
            }
          }

          span {
            font-weight: normal;
            font-size: $font-size-xs;

            em {
              overflow: visible;
            }
          }
        }
      }
    }

    .app-builder-crf-build-entry-screen-crf {
      flex-basis: 100%;
      padding: 0.5rem calc(#{$-element-padding} * 3) calc(#{$-element-padding} * 3) calc(#{$-element-padding} * 3);
    }
  }
}

.app-builder-crf-build-property-id {
  display: flex;
  flex-wrap: nowrap;
}

.app-builder-crf-build-property-icons {
  position: relative;
  flex-shrink: 0;
  margin-right: 1rem;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: -0.4rem;
    display: block;
    width: 1px;
    height: 8px;
    margin-top: -4px;
    background: #999;
  }

  i {
    padding: 0.2rem;
    opacity: 0.3;

    &.enabled {
      opacity: 1;
    }

    &.by {
      &:after {
        content: "x1";
        position: absolute;
        top: 4px;
        right: -3px;
        width: 13px;
        height: 10px;
        border-radius: 50%;
        background: #fafafa;
        font-size: 8px;
        font-family: verdana;
        line-height: 11px;
        text-align: center;
      }

      &.x1,
      &.x2,
      &.x3,
      &.x4,
      &.x9 {
        opacity: 1;
      }

      &.x1:after {
        content: "x1";
      }
      &.x2:after {
        content: "x2";
      }
      &.x3:after {
        content: "x3";
      }
      &.x4:after {
        content: "x4";
      }
      &.x9:after {
        content: "x9";
      }
    }
  }
}

.app-builder-crf-build-entry-screen-crf-title {
  position: sticky;
  top: -0.5rem;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  background: #fff;
  font-weight: bold;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -10px;
    z-index: 0;
    width: 10px;
    background: #fff;
  }

  &:after {
    top: 0;
    right: -10px;
    bottom: 0;
    left: auto;
    width: 10px;
  }
}

.app-builder-crf-build-entry-screen-crf-wrapper {
  min-width: 900px;

  ~ .json--debugger {
    margin-top: 1rem;
  }
}

.app-builder-crf-build-entry-screen-crf-section {
  margin-top: 1.2rem;
  border: 1px solid $-element-border-color;
  border-radius: $app-form-border-radius;
  background: $app-form-background;
  box-shadow: $app-content-box-shadow;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    background: #fdfdfd;
  }

  &.--invalid {
    border-color: $app-color-danger;
  }

  .app-builder-crf-build-entry-screen-crf-section-title {
    position: sticky;
    top: calc(1.2rem + 1.2rem + 1.2rem + 1px);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.7rem;
    border-bottom: 1px solid $-element-border-color;
    border-top-left-radius: $app-form-border-radius;
    border-top-right-radius: $app-form-border-radius;
    background: #fff;
    font-weight: bold;
    line-height: 100%;

    &:before {
      content: "";
      position: absolute;
      top: -2px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      background: #fff;
    }

    &:after {
      content: "";
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      border: 1px solid $-element-border-color;
      border-top-left-radius: $app-form-border-radius;
      border-top-right-radius: $app-form-border-radius;
    }

    > div {
      z-index: 1;
      flex-shrink: 0;
    }

    strong {
      z-index: 1;
      display: flex;
      flex-basis: 100%;
      align-items: center;
      min-height: 24px;
      margin: 0;
      line-height: 24px;

      span.--label-hidden {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          top: 2px;
          right: -3px;
          bottom: 2px;
          left: -3px;
          border-radius: 5px;
          background: repeating-linear-gradient(45deg, #eee, #eee 10px, #999 10px, #999 20px);
          opacity: 0.3;
        }
      }

      em {
        color: gray;
        font-style: normal;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }
  }

  &.--selected {
    border-color: $-element-border-color-selected;

    .app-builder-crf-build-entry-screen-crf-section-title,
    .app-builder-crf-build-entry-screen-crf-section-item-wrapper {
      background: #fafafa;

      &:after {
        border-color: $-element-border-color-selected;
        border-bottom-color: $-element-border-color;
      }
    }
  }
}

.app-builder-crf-build-entry-screen-crf-section-items {
  flex-basis: 100%;
}

.app-builder-crf-build-entry-screen-crf-section-item {
  position: relative;
  margin: 0;
  border-bottom: 1px solid $-element-border-color;

  &:last-child {
    border-bottom: none;

    .app-builder-crf-build-entry-screen-crf-section-item-wrapper {
      border-end-end-radius: $app-form-border-radius;
      border-end-start-radius: $app-form-border-radius;
    }
  }

  .--placeholder {
    padding: 4px 6px;
    border-radius: 5px;
    background: repeating-linear-gradient(45deg, #efefef, #efefef 10px, #ddd 10px, #ddd 20px);
    color: #7f7f7f;
    font-weight: bold;
    line-height: 100%;
  }

  &.--selected {
    z-index: 1;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: -1px;
      bottom: 0;
      width: 1px;
      background: $-element-border-color-selected;
    }

    &:before {
      left: -1px;
      z-index: 1;
    }

    &:after {
      right: -1px;
      z-index: 1;
    }

    > .app-builder-crf-build-entry-screen-crf-section-item-wrapper {
      background: #f0f0f0;

      &:before,
      &:after {
        content: "";
        position: absolute;
        right: 0;
        left: 0;
        height: 1px;
        background: $-element-border-color-selected;
      }

      &:before {
        top: -1px;
      }

      &:after {
        bottom: 0;
      }
    }
  }

  .app-builder-crf-build-entry-screen-crf-section-item-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: calc(#{$-element-padding} * 2) calc(#{$-element-padding} * 2);

    &:hover {
      background: #f4f4f4;
    }

    .--invalid {
      display: flex;
      color: $app-color-danger;

      i:first-child {
        margin-right: 0.2rem;
      }
    }

    .app-builder-crf-build-entry-screen-crf-section-item-wrapper {
      z-index: 0;

      &:hover {
        background: #f1f1f1;
      }

      &.--inline {
        .app-builder-crf-build-entry-screen-crf-section-item-property {
          strong {
            display: flex;
            flex-wrap: wrap;
            margin-top: 1rem;

            > * {
              margin-top: 0.5rem;
            }

            label {
              display: block;
            }

            .app-builder-crf-build-property-icons {
              position: absolute;
              top: 2px;
              left: $-element-padding;
              margin-top: 0;
              white-space: nowrap;

              &:after {
                display: none;
              }
            }
          }

          .app-builder-crf-build-misc-buttons {
            position: absolute;
            top: 0;
            right: 0;

            button {
              padding: 0.3rem 0.4rem;

              i {
                margin-right: 0;

                &.--icon-close {
                  margin-right: -0.1rem;
                }
              }
            }
          }
        }
      }
    }

    .app-builder-crf-build-entry-screen-crf-section-item-body .app-builder-crf-build-entry-screen-crf-section-items > .--selected {
    }

    .app-builder-crf-build-entry-screen-crf-section-item-head {
      .app-builder-crf-build-entry-screen-crf-section-item-head-elem {
        display: flex;
        flex-direction: column;

        > *:first-child {
          margin-bottom: 0.6rem;
        }

        .app-builder-crf-build-entry-screen-crf-section-item-property {
          display: flex;
          align-items: center;
          justify-content: space-between;
          overflow: hidden;
          padding-bottom: 0.6rem;
          border-bottom: 1px solid fade-out($-element-border-color, 0.2);

          .app-builder-crf-build-misc-buttons {
            min-width: 370px;
          }

          > strong {
            display: flex;
            align-items: center;

            label {
              overflow: hidden;
              max-width: 250px;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .app-builder-crf-build-entry-screen-crf-section-item-id,
        .app-builder-crf-build-entry-screen-crf-section-item-type {
          display: inline-block;
          padding: 0.25rem 0.4rem 0.18rem 0.4rem;
          border-radius: 0.65rem;
          font-style: normal;
          font-size: 10px;
          line-height: 100%;
          text-transform: uppercase;
        }

        .app-builder-crf-build-entry-screen-crf-section-item-id {
          margin-right: 0.5rem;
          border: 1px solid darken($color-primary, 10%);
          border-left: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          background: $color-primary;
          color: #fff;
        }

        .app-builder-crf-build-entry-screen-crf-section-item-type {
          margin-right: 0.5rem;
          border: 1px solid #ccc;
          background: #efefef;

          &.--with-domain {
            margin-right: 0;
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            white-space: nowrap;
          }
        }
      }
    }

    &.--description {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }
    }

    &.--check {
      .--placeholder {
        margin-top: 4px;
      }
    }

    &.--table {
      .app-builder-crf-build-entry-screen-crf-section-item-wrapper {
        padding: $-element-padding;
      }

      .--table {
        width: 100%;

        .--table-wrap {
          overflow: auto;
          width: 100%;
          border: 1px solid $-element-border-color;
          border-radius: $app-form-border-radius;

          td {
            position: relative;
            padding: $-element-padding;

            .app-builder-crf-build-entry-screen-crf-section-item {
              position: static;

              &.--selected {
                &:before {
                  content: "";
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  z-index: 0;
                  width: auto;
                  height: auto;
                  border: 1px solid $-element-border-color-selected;
                  background: transparent;
                  background: #f0f0f0;
                }

                .app-builder-crf-build-entry-screen-crf-section-item-wrapper {
                  &:before,
                  &:after {
                    display: none;
                  }
                }
              }
            }
          }
        }

        table {
          width: 100%;
          border-collapse: collapse;
          border-style: hidden;
          table-layout: fixed;

          th,
          td {
            overflow: hidden;
            padding: 0.3rem;
            border: 1px solid $-element-border-color;
          }

          td {
            vertical-align: top;

            .app-builder-crf-build-misc-buttons {
              min-width: auto !important;
            }
          }
        }
      }
    }

    &.--sys-val {
      input.--style-plain {
        border-color: #f0f0f0 !important;
      }
    }

    .app-builder-crf-build-entry-screen-crf-section-item-body {
      $-min-height: 32px;

      display: flex;
      align-items: flex-start;
      margin-top: 0.1rem;
      font-size: $font-size-sm;

      .app-builder-crf-build-entry-screen-crf-section-item-attrs {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        height: $-min-height;
      }

      input {
        width: 100%;
        max-width: 200px;
        height: $-min-height;
        padding: 0 0.4rem;
        border: 1px solid #ccc;
        border-radius: 0.2rem;
        line-height: $-min-height;

        &:disabled {
          background: #fafafa;
        }
      }

      .cr-input-group {
        > span {
          display: flex;
          align-items: center;
          min-height: $-min-height;

          > em {
            margin: 0 0.35rem;
            font-style: normal;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

.app-builder-crf-build-entry-screen-crf-property-attribute {
  display: flex;

  label.ms-Label,
  span.ms-Checkbox-text {
    font-weight: 600 !important;
    font-size: $font-size-sm !important;
    -webkit-font-smoothing: auto;
  }

  > * {
    flex-basis: 100%;
  }

  > .cr-button:last-child {
    min-width: 32px;
    max-width: 32px;
    margin-left: 1px;
    padding: 0;
  }

  textarea {
    resize: none;
  }

  > .cr-input:not(.ms-TextField--multiline) > .ms-TextField-wrapper,
  > .cr-dropdown {
    display: flex;
    align-items: center;
    overflow: hidden;

    .ms-Dropdown {
      flex-basis: 100%;
      overflow: hidden;
    }

    .ms-layer {
      display: none;
    }

    > * {
      &:not(:first-child) {
        flex-basis: 100%;
      }

      &:first-child {
        flex-shrink: 0;
        overflow: hidden;
        max-width: 170px;
        padding-right: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .cr-checkbox {
    .ms-Checkbox {
      align-items: center;
      min-height: 32px;
    }
  }

  .cr-checkbox:first-child {
    label {
      justify-content: end;
      direction: rtl;

      .ms-Checkbox-text {
        margin-right: 10px;
        margin-left: 0;
        font-weight: bold;
        font-size: $font-size-sm;
      }
    }
  }

  &.--min-max-date {
    .cr-checkbox {
      max-width: 65px;

      label {
        justify-content: flex-end;
      }
    }
  }
}

.app-builder-crf-build-entry-screen-crf-section-item-enum {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3px !important;
  margin-right: -0.15rem;
  margin-bottom: 0;
  margin-left: -0.15rem;
  padding: 0;
  list-style: none;

  &:not(:first-child) {
    margin-top: 0.15rem !important;
  }

  li {
    display: flex;
    align-items: center;
    margin: 0.15rem;
    padding: 0.25rem 0.4rem;
    border: 1px solid #ccc;
    border-radius: 0.4rem;
    background: #f8f8f8;
    font-size: $font-size-xs;
    line-height: 100%;

    .app-builder-crf-build-entry-screen-crf-section-item-id {
      display: inline-block;
      margin-top: -0.18rem;
      margin-bottom: -0.18rem;
      margin-left: -0.37rem;
      padding: 0.25rem 0.22rem 0.18rem 0.4rem;
      border: 1px solid darken($color-primary, 10%);
      border-left: 0;
      border-radius: 0.4rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background: $color-primary;
      color: #fff;
      font-style: normal;
      font-size: 10px;
      line-height: 100%;
      text-transform: uppercase;
    }
  }

  &.--space {
    li strong {
      display: flex;
      padding-right: 5px;

      .cr-icon {
        margin-left: 5px;
      }
    }
    .--prefix {
      padding-right: 5px;
    }

    .--tail {
      padding-left: 5px;
    }
  }

  &.--none {
    li strong {
      padding-right: 0;
    }
  }

  &.--size-small {
    li {
      padding: 0.2rem 0.22rem;
      font-size: 10px;
      font-family: verdana;
    }
  }
}

.app-builder-crf-payload-edit--advanced {
  .ms-Dialog-main {
    width: 600px;
    min-width: 320px;
    max-width: 100%;
  }
}

.app-builder-crf-payload-save {
  .ms-Dialog-main {
    width: 1000px;
    min-width: 320px;
    max-width: 100%;
  }

  .ms-Dialog-content {
    overflow: auto;
    min-height: 5rem;
    max-height: calc(100vh - 17rem);
  }

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    th,
    td {
      padding: 0.2rem 0.5rem;
      border: 1px solid #e0e0e0;
      word-break: break-all;
    }

    th {
      background: #f7f7f7;
    }

    .--thin {
      padding: 0;
    }

    .--_add {
      td {
        background-color: lighten($color-primary, 49%);
      }
    }

    .--_modify {
      td {
        background-color: lighten($app-color-warning, 43%);
      }
    }

    .--_delete {
      td {
        background-color: lighten($app-text-color, 73%);
      }
    }

    .--_sort {
      td {
        background-color: lighten($app-color-warning, 43%);
      }
    }

    .--before,
    .--after {
      div {
        line-height: 1.2rem;
        word-break: break-all;

        pre {
          overflow: auto;
          max-height: 100px;
          margin: 0;
          padding: 0.2rem 0.5rem;
          letter-spacing: -0.5px;

          &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: #efefef;
          }

          *::-webkit-scrollbar-track {
            background-color: #e4e4e4;
          }

          *::-webkit-scrollbar-thumb {
            background-color: #999;
          }
        }
      }

      em {
        display: inline-block;
        padding: 0.25rem 0.22rem 0.18rem 0.4rem;
        border: 1px solid darken($color-primary, 10%);
        border-left: 0;
        border-radius: 0.4rem;
        background: $color-primary;
        color: #fff;
        font-style: normal;
        font-size: $font-size-sm;
        line-height: 100%;
      }
    }

    .--check {
      max-width: 50px;

      .ms-Checkbox-label {
        align-items: center;
        justify-content: center;

        .ms-Checkbox-checkbox {
          top: auto;
          bottom: auto;
          left: auto;
          margin: 0;
        }
      }
    }

    .--target {
      overflow: hidden;
      word-break: keep-all;
    }

    .--add {
      color: $color-primary;
    }

    .--flag {
      text-align: center;
    }
  }
}

.app-builder-ip {
  .--ip-category-list {
    .--footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: 5px 10px 5px 10px;
    }
  }

  .--ip-drug-list {
    .--footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: 5px 10px 5px 10px;
    }
  }

  .--ip-schedule-detail-list {
    .--footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: 5px 10px 5px 10px;
    }
  }
}

.--ip-schedule-detail-edit-dialog {
  .ms-Dialog-main {
    width: 100%;
    min-width: 320px;
    max-width: 1000px;
    min-height: 400px;
    max-height: 700px;
  }
}
