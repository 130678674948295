.hb-code-input {
  $-code-spacing: 2px;

  display: flex;
  justify-content: center;
  margin: $app-hybrid-component-margin calc(#{$-code-spacing} * -1);

  > div {
    max-width: 50px;
    height: 50px;
    margin: $-code-spacing;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.09);
  }
  
  input {
    width: 100%;
    height: 100%;
    border: 2px solid #fff;
    border-radius: 2px;
    font-size: 22px;
    text-align: center;
    
    &:focus {
      border-color: $color-primary;
      outline: none;
    }
  }
}