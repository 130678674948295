.ml-1 {
  margin-left: 0.2rem;
}
.ml-2 {
  margin-left: 0.4rem;
}
.ml-3 {
  margin-left: 0.6rem;
}

.mx-1 {
  margin-right: 0.2rem;
  margin-left: 0.2rem;
}
.mx-2 {
  margin-right: 0.4rem;
  margin-left: 0.4rem;
}
.mx-3 {
  margin-right: 0.6rem;
  margin-left: 0.6rem;
}

.mb-3 {
  margin-bottom: 0.6rem;
}

.mb-10 {
  margin-bottom: 2rem;
}
