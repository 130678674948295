.hb-divider {
  display: block;
  width: 100%;
  margin: 1rem 0;

  hr {
    width: 100%;
    max-width: 300px;
    height: 1px;
    margin: auto;
    padding: 0;
    border: 0;
    background: blue;
  }

  &.--space-1 { margin: 1rem 0; }
  &.--space-2 { margin: 2rem 0; }
  &.--space-3 { margin: 3rem 0; }
  &.--space-4 { margin: 4rem 0; }
  &.--space-5 { margin: 5rem 0; }

  &.--color-transparent {
    hr {
      background: transparent;
    }
  }
}