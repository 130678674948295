@import-normalize;
@import "variables.scss";
@import "styles.scss";

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background: $app-content-background;
  color: $app-text-color;
}

#root {
  height: 100%;
}

.material-icons,
.material-icons-outlined {
  vertical-align: middle;
  font-size: inherit;
}

html.noanim {
  * {
    transition: none !important;

    &:after,
    &:before {
      transition: none !important;
    }
  }
}

html.app-hybrid,
html.app-hybrid body {
  width: 100%;
  min-height: 100%;
  background: #fff;
}