.cr-label {
  display: flex;
  padding: 5px 0;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;

  &.font--small {
    font-size: $font-size-base;
  }
  &.font--medium {
    font-size: $font-size-4xl;
  }
  &.font--large {
    font-size: $font-size-6xl;
  }
}

.cr-dropdown .ms-Label,
.cr-input .ms-Label {
  height: 23px;
  line-height: 13px;
}
