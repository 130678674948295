.hb-button,
.hb-request-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: $app-hybrid-component-margin 0;
  user-select: none;

  button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    max-width: 200px;
    padding: 10px;
    border: 0px solid $color-primary;
    border-radius: 2px;
    background: $color-primary;
    color: #fff !important;
    box-shadow: 0 0 10px rgb(200, 200, 200);
    cursor: pointer;

    .cr-icon {
      margin: 0 7px;
      font-size: $font-size-base;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    &:focus-visible {
      outline: none;

      &:after {
        content: "";
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        left: 1px;
        border: 2px dashed #000 !important;
        opacity: 0.5;
      }
    }
  }

  &.--text-underline {
    button {
      text-decoration: underline;

      &:hover {
        font-weight: 500;
      }

      &:active {
        font-weight: 550;
      }
    }
  }

  &.--margin-top--1 {
    margin-top: calc(#{$app-hybrid-component-margin} / -2);
  }

  &.--color-primary {
    button {
      border-color: $color-primary;
      background-color: $color-primary;

      &:hover {
        border-color: darken($color-primary, 3%);
        background-color: darken($color-primary, 3%);
      }

      &:active {
        border-color: darken($color-primary, 5%);
        background-color: darken($color-primary, 5%);
      }
    }
  }

  &.--color-danger {
    button {
      border-color: $app-color-danger;
      background-color: $app-color-danger;

      &:hover {
        border-color: darken($app-color-danger, 3%);
        background-color: darken($app-color-danger, 3%);
      }

      &:active {
        border-color: darken($app-color-danger, 5%);
        background-color: darken($app-color-danger, 5%);
      }
    }
  }

  &.--color-success {
    button {
      border-color: $app-color-success;
      background-color: $app-color-success;

      &:hover {
        border-color: darken($app-color-success, 3%);
        background-color: darken($app-color-success, 3%);
      }

      &:active {
        border-color: darken($app-color-success, 5%);
        background-color: darken($app-color-success, 5%);
      }
    }
  }

  &.--color-warning {
    button {
      border-color: $app-color-warning;
      background-color: $app-color-warning;

      &:hover {
        border-color: darken($app-color-warning, 3%);
        background-color: darken($app-color-warning, 3%);
      }

      &:active {
        border-color: darken($app-color-warning, 5%);
        background-color: darken($app-color-warning, 5%);
      }
    }
  }

  &.--color-transparent {
    button {
      color: $app-text-color !important;
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .--loading {
    .ms-Button-flexContainer {
      &:after {
        border-color: #fff;
        border-left-color: transparent;
      }
    }
  }

  &.--size-default {
    > button > span {
      font-size: $font-size-base;
    }
  }
  &.--size-large {
    > button > span {
      font-size: $font-size-lg;
    }
  }
  &.--size-x-large {
    > button > span {
      font-size: $font-size-xl;
    }
  }
  &.--size-2x-large {
    > button > span {
      font-size: $font-size-2xl;
    }
  }
  &.--size-3x-large {
    > button > span {
      font-size: $font-size-3xl;
    }
  }
  &.--size-4x-large {
    > button > span {
      font-size: $font-size-4xl;
    }
  }
  &.--size-5x-large {
    > button > span {
      font-size: $font-size-5xl;
    }
  }
  &.--size-6x-large {
    > button > span {
      font-size: $font-size-6xl;
    }
  }
  &.--size-7x-large {
    > button > span {
      font-size: $font-size-7xl;
    }
  }
  &.--size-8x-large {
    > button > span {
      font-size: $font-size-8xl;
    }
  }

  &.--disabled {
    button {
      background-color: #a19f9d;
      color: #bbb;
      cursor: default;

      &:hover {
        background-color: #a19f9d;
        color: #bbb;
      }

      &:active {
        background-color: #a19f9d;
        color: #bbb;
      }
    }
  }
}
