.al-form {
  display: block;
  max-width: 100%;

  > label {
    display: block;
    margin: 0 0 0.2rem 0;
    padding: 0;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    text-transform: uppercase;
  }

  .al-field {
    position: relative;
    z-index: 1;
    display: block;
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;
    padding: $app-form-field-padding;
    border: $app-form-border;
    border-radius: $app-form-border-radius;
    background: $app-form-background;
    color: $app-form-color;
    font-size: $font-size-base;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::placeholder {
      color: lighten($app-form-color, 15%) !important;
      transition: all ease-in-out 0.15s;
    }

    &:hover {
      border-color: darken($app-form-border-color, 10%);
    }

    &:focus {
      border-color: $app-color-focus !important;
      box-shadow: none;
      outline: none;

      &::placeholder {
        text-indent: 0.2rem;
      }
    }

    &:active {
      background: $app-form-background !important;
      color: lighten($app-form-color, 60%) !important;
      color: $app-form-color !important;
      box-shadow: none !important;
    }

    &:disabled {
      background: darken($app-form-background, 2%) !important;
      color: lighten($app-form-color, 50%) !important;

      &::placeholder {
        color: fade-out(lighten($app-form-color, 50%), 0.1) !important;
      }
    }
  }
}
