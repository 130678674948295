.cr-message-bar {
  .ms-MessageBar {
    border-radius: 0.5rem;
  }

  .ms-MessageBar-content {
    padding: 0.75rem;
  }

  .ms-MessageBar-innerText {
    font-size: $font-size-base;
  }
}
