$color-gray-100: #f7fafc;
$color-gray-200: #edf2f7;
$color-gray-300: #e2e8f0;
$color-gray-400: #cbd5e0;
$color-gray-500: #a0aec0;
$color-gray-600: #718096;
$color-gray-700: #4a5568;
$color-gray-800: #2d3748;
$color-gray-900: #1a202c;

$color-red-100: #fff5f5;
$color-red-200: #fed7d7;
$color-red-300: #feb2b2;
$color-red-400: #fc8181;
$color-red-500: #f56565;
$color-red-600: #e53e3e;
$color-red-700: #c53030;
$color-red-800: #9b2c2c;
$color-red-900: #742a2a;

$color-orange-100: #fffaf0;
$color-orange-200: #feebc8;
$color-orange-300: #fbd38d;
$color-orange-400: #f6ad55;
$color-orange-500: #ed8936;
$color-orange-600: #dd6b20;
$color-orange-700: #c05621;
$color-orange-800: #9c4221;
$color-orange-900: #7b341e;

$color-yellow-100: #fffff0;
$color-yellow-200: #fefcbf;
$color-yellow-300: #faf089;
$color-yellow-400: #f6e05e;
$color-yellow-500: #ecc94b;
$color-yellow-600: #d69e2e;
$color-yellow-700: #b7791f;
$color-yellow-800: #975a16;
$color-yellow-900: #744210;

$color-green-100: #f0fff4;
$color-green-200: #c6f6d5;
$color-green-300: #9ae6b4;
$color-green-400: #68d391;
$color-green-500: #48bb78;
$color-green-600: #38a169;
$color-green-700: #2f855a;
$color-green-800: #276749;
$color-green-900: #22543d;

$color-teal-100: #e6fffa;
$color-teal-200: #b2f5ea;
$color-teal-300: #81e6d9;
$color-teal-400: #4fd1c5;
$color-teal-500: #38b2ac;
$color-teal-600: #319795;
$color-teal-700: #2c7a7b;
$color-teal-800: #285e61;
$color-teal-900: #234e52;

$color-blue-100: #ebf8ff;
$color-blue-200: #bee3f8;
$color-blue-300: #90cdf4;
$color-blue-400: #63b3ed;
$color-blue-500: #4299e1;
$color-blue-600: #3182ce;
$color-blue-700: #2b6cb0;
$color-blue-800: #2c5282;
$color-blue-900: #2a4365;

$color-indigo-100: #ebf4ff;
$color-indigo-200: #c3dafe;
$color-indigo-300: #a3bffa;
$color-indigo-400: #7f9cf5;
$color-indigo-500: #667eea;
$color-indigo-600: #5a67d8;
$color-indigo-700: #4c51bf;
$color-indigo-800: #434190;
$color-indigo-900: #3c366b;

$color-purple-100: #faf5ff;
$color-purple-200: #e9d8fd;
$color-purple-300: #d6bcfa;
$color-purple-400: #b794f4;
$color-purple-500: #9f7aea;
$color-purple-600: #805ad5;
$color-purple-700: #6b46c1;
$color-purple-800: #553c9a;
$color-purple-900: #44337a;

$color-pink-100: #fff5f7;
$color-pink-200: #fed7e2;
$color-pink-300: #fbb6ce;
$color-pink-400: #f687b3;
$color-pink-500: #ed64a6;
$color-pink-600: #d53f8c;
$color-pink-700: #b83280;
$color-pink-800: #97266d;
$color-pink-900: #702459;

$color-force-disable: darken(#d0d2d5, 10%);
$color-force-disable-hover: darken(#d0d2d5, 15%);
$color-sdv: darken(#24e362, 10%);
$color-sdv-hover: darken(#24e362, 15%);
$color-freeze: darken(#063aa1, 10%);
$color-freeze-hover: darken(#063aa1, 15%);
$color-sign: darken(#f5dd92, 20%);
$color-sign-hover: darken(#f5dd92, 25%);
$color-lock: darken(#f47560, 10%);
$color-lock-hover: darken(#f47560, 15%);
