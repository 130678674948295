.al-header {
  $-icon-size: 4rem;
  $-icon-spacing: 1rem;
  $-icon-region: $-icon-size + $-icon-spacing;

  @include transition(0.2s);
  display: flex;
  align-items: stretch;

  width: 100%;
  margin-bottom: $app-content-box-padding;

  @media (max-width: $break-md) {
    flex-direction: column;

    .btn {
      padding-top: 0.5rem;
      padding-left: 0 !important;

      a,
      .al-button {
        display: block;
        width: 100%;
        text-decoration: none;
      }
    }
  }

  .head {
    display: flex;
    flex-grow: 1;
    align-items: center;

    .icon {
      @include transition(0.2s);

      position: relative;
      display: block;
      flex-grow: 0;
      flex-shrink: 0;
      width: $-icon-size;
      height: $-icon-size;

      border-radius: $app-content-box-radius;
      background: $app-content-box-background;
      box-shadow: $app-content-box-shadow;
      line-height: $-icon-size;
      text-align: center;

      i {
        $-size: $font-size-4xl;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: calc(#{$-size} / -2);
        margin-left: calc(#{$-size} / -2.2);
        font-size: $-size;
      }
    }

    .title {
      margin-left: 1rem;

      strong {
        display: block;
        font-size: $font-size-2xl;
      }

      p {
        display: block;
        margin: 0.2rem 0 0 0;
        font-size: $font-size-base;
        line-height: 1.3;
      }
    }
  }

  .btn {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    justify-content: flex-end;
    padding-left: 2rem;
    text-align: right;
  }
}
