@mixin rippleEffect($color, $amountIn: 5%, $amountOut: 15%, $daken: false) {
  background-color: $color;
  transition: all ease-in-out 0.4s;

  &:hover,
  &:focus {
    @if ($daken) {
      background: darken($color, $amountIn) radial-gradient(circle, transparent 1%, darken($color, $amountIn) 1%) center / 15000%;
    } @else {
      background: lighten($color, $amountIn) radial-gradient(circle, transparent 1%, lighten($color, $amountIn) 1%) center / 15000%;
    }
  }

  &:active {
    @if ($daken) {
      background-color: darken($color, $amountOut);
    } @else {
      background-color: lighten($color, $amountOut);
    }
    background-size: 100%;
    transition: background 0s;
  }
}

@mixin transition($duration: 0.15s) {
  transition: all ease-in-out $duration;
}
