.hb-paragraph {
  display: block;
  position: relative;
  margin: $app-hybrid-component-margin 0;
  line-height: 140%;
  word-break: keep-all;

  .hb-paragraph-icon {
    display: inline-block;
    margin-right: 5px;
    font-size: 3rem;
    vertical-align: middle;
    line-height: 1;
  }

  &.--align-center {
    text-align: center;
  }

  &.--align-left {
    text-align: left;
  }

  &.--align-right {
    text-align: right;
  }

  &.--margin-1 {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  &.--margin-2 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  &.--margin-3 {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  &.--margin-4 {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  &.--margin-5 {
    margin-right: 5rem;
    margin-left: 5rem;
  }

  &.--spacing-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  &.--spacing-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  &.--spacing-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  &.--spacing-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  &.--spacing-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  &.--size-large {
    font-size: $font-size-lg;
    &.--prefix-bullet:before {
      margin-left: 0.4rem;
      top: 0.5rem;
      left: 0.1rem;
      width: 0.2rem;
      height: 0.2rem;
    }
  }
  &.--size-x-large {
    font-size: $font-size-xl;
    &.--prefix-bullet:before {
      margin-left: 0.3rem;
      top: 0.5rem;
      left: 0.1rem;
      width: 0.3rem;
      height: 0.3rem;
    }
  }
  &.--size-2x-large {
    font-size: $font-size-2xl;
    &.--prefix-bullet:before {
      margin-left: 0.3rem;
      top: 0.55rem;
      left: 0.08rem;
      width: 0.3rem;
      height: 0.3rem;
    }
  }
  &.--size-3x-large {
    font-size: $font-size-3xl;
    &.--prefix-bullet:before {
      margin-left: 0.3rem;
      top: 0.7rem;
      left: 0.05rem;
      width: 0.4rem;
      height: 0.4rem;
    }
  }
  &.--size-4x-large {
    font-size: $font-size-4xl;
    &.--prefix-bullet:before {
      margin-left: 0.3rem;
      top: 0.8rem;
      left: 0.05rem;
      width: 0.4rem;
      height: 0.4rem;
    }
  }
  &.--size-5x-large {
    font-size: $font-size-5xl;
    &.--prefix-bullet:before {
      margin-left: 0.3rem;
      top: 1.1rem;
      left: 0.05rem;
      width: 0.4rem;
      height: 0.4rem;
    }
  }
  &.--size-6x-large {
    font-size: $font-size-6xl;
    &.--prefix-bullet:before {
      margin-left: 0.3rem;
      top: 1.3rem;
      left: 0.05rem;
      width: 0.5rem;
      height: 0.5rem;
    }
  }
  &.--size-7x-large {
    font-size: $font-size-7xl;
    &.--prefix-bullet:before {
      margin-left: 0.3rem;
      top: 1.5rem;
      left: 0.05rem;
      width: 0.5rem;
      height: 0.5rem;
    }
  }
  &.--size-8x-large {
    font-size: $font-size-8xl;
    &.--prefix-bullet:before {
      margin-left: 0.3rem;
      top: 1.8rem;
      left: 0.05rem;
      width: 0.6rem;
      height: 0.6rem;
    }
  }

  &.--color-gray {
    color: $color-gray-600;
    &.--prefix-bullet:before {
      background: $color-gray-600;
    }
  }
  &.--color-warning {
    color: $app-color-warning;
    &.--prefix-bullet:before {
      background: $app-color-warning;
    }
  }
  &.--color-danger {
    color: $app-color-danger;
    &.--prefix-bullet:before {
      background: $app-color-danger;
    }
  }
  &.--color-success {
    color: $app-color-success;
    &.--prefix-bullet:before {
      background: $app-color-success;
    }
  }
  &.--color-primary {
    color: $color-primary;
    &.--prefix-bullet:before {
      background: $color-primary;
    }
  }

  &.--bold {
    font-weight: bold;
  }

  &.--prefix-bullet {
    & {
      padding-left: 1.1rem;
    }
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      border-radius: 50%;
    }
  }
}
