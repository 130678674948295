.al-container {
  .al-card {
    position: relative;
    //overflow: hidden;

    > .head {
      display: table;

      .al-icon {
        float: left;
        margin-right: 0.6rem;
      }

      .title {
        display: table-cell;
        vertical-align: middle;
        line-height: 1;

        strong {
          font-size: $font-size-xl;
        }

        p {
          margin-top: 0.4rem;
          font-size: $font-size-base;
        }

        &.descriptor {
          strong {
            font-weight: $font-weight-normal;
            font-size: $font-size-base;
          }
          p {
            margin-top: 0.3rem;
            font-weight: $font-weight-bold;
            font-size: $font-size-xl;
          }
        }

        > * {
          overflow: hidden;
          margin: 0;
          padding: 0;
          text-overflow: ellipsis;
          white-space: pre-wrap;
          word-break: break-word;
        }
      }
    }

    > .cont {
      position: relative;
      height: 100%;
      margin-top: 0.7rem;
    }

    &.sm {
      > .head {
        .title {
          strong {
            font-size: $font-size-base;
          }
        }
      }
    }

    &.--height-base {
      min-height: 170px;

      &.--wrap-height {
        overflow: hidden;
        height: 170px;
      }
    }

    &.--height-double {
      min-height: 270px;
    }

    &.--height-one-half {
      min-height: 150px;
    }

    &.--height-one-triple {
      min-height: 300px;
    }

    &.--wrap-height {
      padding-bottom: 0 !important;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 2rem;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $app-content-box-background 75%);
        pointer-events: none;
      }
    }
  }
}
