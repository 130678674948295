.cr-footer-bar {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  height: $app-footer-bar-height;
  margin-left: -5px;
  background: #faf7f5;
  transition: left ease-in-out $app-sidebar-transition-duration;

  .ms-Button {
    &.right {
      position: fixed;
      right: 1.5rem;
    }
    
    .ms-Button-label {
      white-space: nowrap;
    }
  }

  .ms-Button {
    margin-left: 5px;
  }

  > .ms-Stack {
    flex: 1;
    flex-flow: row;

    > * {
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
      }
    }

    .ms-Button {
      display: flex;
    }
  }

  .cr-footer-bar-lr {
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    align-items: center;

    &.--with-left {
      &.--with-center {
        > div {
          flex-basis: 33.3333%;
        }
      }
    }

    .--left,
    .--center,
    .--right {
      display: flex;

      &.--center {
        justify-content: center;
      }

      &.--right {
        justify-content: flex-end;
      }
    }

    .--left {
      .cr-buttons {
        z-index: 1;
      }
    }

    .--search {
      display: flex;
      position: relative;

      input {
        max-width: 170px;
        width: 100%;
        border-right: 0;
      }

      .cr-button {
        margin-left: 0;
        border-radius: 0;
        padding: 0;
        width: 40px;
        min-width: 0;
      }

      .--navigator {
        position: absolute;
        top: 6px;
        left: -118px;
        width: 110px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        span {
          margin-right: 5px;
          font-size: $font-size-sm;
        }
        
        .cr-button {
          width: 20px;
          height: 20px;
          margin-left: 1px;
          border-radius: 2px;
          justify-content: center;

          span {
            margin: 0;
          }

          i {
            font-size: $font-size-xs;
          }
        }
      }
    }
  }
}
