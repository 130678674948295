.cr-grid {
  $-spacing: 1rem;

  display: flex;
  flex-flow: row nowrap;
  margin: calc(#{$-spacing} * -1);

  &.cr-grid-horizontal {
    overflow-x: auto;
  }

  &.--full-height {
    min-height: calc(100vh - 22.2rem);
  }

  .cr-grid-item {
    flex-basis: 100%;
    min-height: 100%;
    margin: $-spacing $-spacing $-spacing 0;

    &:first-child {
      margin-left: $-spacing;
    }

    .ms-Button-flexContainer {
      .ms-Button-textContainer {
        display: inline-block;
        flex: none;
      }
    }

    .cr-section,
    .cr-section-inner {
      display: flex;
      flex-basis: 100%;
      min-height: 100%;
      margin: 0;

      .cr-section-inner-content {
        .cr-section,
        .cr-section-inner {
          display: block;
          flex: none;
          margin: 2rem 0 2rem 0;

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.--break {
    flex-flow: row wrap;

    .cr-grid-item {
      margin-left: $-spacing;
    }

    .cr-grid-item:not(:last-child) {
      margin-bottom: 0;
    }
  }
}
