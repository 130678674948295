.al-container {
  width: 100%;

  .al-row.el-5 .al-col {
    width: 20%;
  }

  .al-row.el-4 .al-col {
    width: 25%;
  }

  .al-row.el-3 .al-col {
    width: 33.33333%;
  }

  .al-row.el-2 .al-col {
    width: 50%;
  }

  .al-row.el-1 .al-col {
    width: 100%;
  }

  .al-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -$app-content-box-spacing;
    padding: calc(#{$app-content-box-spacing} / 2);

    .al-col {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
      padding: calc(#{$app-content-box-spacing} / 2);

      > .al-card {
        height: 100%;
        min-height: 100%;
      }
    }
  }
}

.comment-container {
  width: 100%;
  background-color: #fff;

  .comment-wrapper {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px dotted #DDD;
  }

  .comment-wrapper:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
  }

  .comment-user {
    font-weight: 600;

    em {
      margin-left: 5px;
      padding: 1px 4px;
      border-radius: 5px;
      background: $color-orange-200;
      color: $color-gray-900;
      font-style: normal;
      font-size: $font-size-xs;
    }
    
    .comment-user-meta {
      display: block;
      margin-top: 5px;
      color: $color-gray-700;
      font-weight: 500;
      font-style: normal;
      font-size: $font-size-xs;
    }
    .comment-user-flex-wrap {
      display: flex;
      height: 17px;
      .icon-wrap {
        margin-left: auto;
      }
    }
  }

  .comment-content {
    margin-top: 0.5rem;
    line-height: 145%;
  }

  .compose {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
  }

  .cr-input {
    padding: none;
    
    .ms-TextField-field {
      resize: none;
    }
  }
}

.al-container {
  @media (max-width: $break-3xl) {
    .al-row.el-3xl-3 .al-col {
      flex-grow: unset;
      flex-basis: unset;
      width: 33.33333%;
    }

    .al-row.el-3xl-2 .al-col {
      flex-grow: unset;
      flex-basis: unset;
      width: 50%;
    }

    .al-row.el-3xl-1 .al-col {
      flex-grow: unset;
      flex-basis: unset;
      width: 100%;
    }
  }

  @media (max-width: $break-2xl) {
    .al-row.el-2xl-2 .al-col {
      flex-grow: unset;
      flex-basis: unset;
      width: 50%;
    }
    .al-row.el-2xl-1 .al-col {
      flex-grow: unset;
      flex-basis: unset;
      width: 100%;
    }
  }

  @media (max-width: $break-xl) {
    .al-row.el-xl-2 .al-col {
      flex-grow: unset;
      flex-basis: unset;
      width: 50%;
    }
    .al-row.el-xl-1 .al-col {
      flex-grow: unset;
      flex-basis: unset;
      width: 100%;
    }
  }

  @media (max-width: $break-lg) {
    .al-row.el-lg-2 .al-col {
      flex-grow: unset;
      flex-basis: unset;
      width: 50%;
    }
    .al-row.el-lg-1 .al-col {
      flex-grow: unset;
      flex-basis: unset;
      width: 100%;
    }
  }
}
