.al-radios {
  position: relative;
  overflow: hidden;

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .al-radios-wrapper {
  }
}

.al-radio {
  margin-top: 0.3rem;

  > input {
    &:checked,
    &:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    &:checked + label,
    &:not(:checked) + label {
      position: relative;
      display: block;
      padding-left: 28px;
      color: $app-form-color;
      line-height: 20px;
      cursor: pointer;
    }
    &:checked + label:before,
    &:not(:checked) + label:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      border: 1px solid $app-form-border-color;
      border-radius: 100%;
      background: #fff;
    }
    &:checked + label:after,
    &:not(:checked) + label:after {
      content: "";
      position: absolute;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: $app-highlight-color;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    &:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    &:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
