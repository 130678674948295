.cr-nav-layout {
  .cr-nav-layout-nav {
    max-width: $app-sidebar-width;
  }

  .cr-nav-layout-nav.ms-StackItem,
  .cr-nav-layout-content.ms-StackItem {
    > .cr-section {
      margin: 0;
    }
  }
}