.cr-section {
  margin: 1rem 0 1rem 0;

  .cr-section-inner {
    padding: calc(#{$app-content-box-padding} * 1.2);
    background-color: $app-content-box-background;
    box-shadow: $app-content-box-shadow;

    .cr-section-inner-wrap {
      width: 100%;
    }

    .cr-section-inner-top-row {
      display: flex;
    }

    .cr-section-inner-title {
      margin-bottom: 1.5rem;
      font-weight: $font-weight-bold;
      font-size: $font-size-xl;

      span {
        white-space: nowrap;
        color: $color-primary;
      }
    }

    .cr-section-inner-title-right {
      margin-left: auto;
    }

    .cr-section-inner-title-normal {
      margin-bottom: 1.5rem;
      font-size: $font-size-lg !important;
    }

    .cr-section-inner-title-small {
      margin-bottom: 0.75rem;
      font-size: $font-size-sm !important;
    }
  }

  .cr-section-inner-no-padding {
    padding: 0;
  }

  &.--height-chart {
    display: inline-block;
    width: 100%;
    height: 310px;

    .cr-section-inner-content,
    .fx-guard,
    .fx-guard-loading {
      width: 100%;
      height: 270px;
      opacity: 1 !important;
    }

    .--description {
      display: block;
      margin: 1rem 0 0 0;
      padding: 0;
      color: #555;
      font-size: $font-size-sm;
      line-height: 140%;
      text-align: center;
    }

    .--no-data {
      height: 100%;
      padding: 4rem;
      color: #555;
      text-align: center;
      box-shadow: $app-content-box-shadow;

      .--large {
        font-size: 50px;
      }

      .--mediun {
        font-size: 35px;
      }

      .--small {
        font-size: 20px;
      }
    }
  }

  &.--without-shadow {
    .cr-section-inner {
      box-shadow: none;
    }
  }

  .cr-section-float-right {
    position: relative;
    margin-bottom: 2rem;
    right: 0;
    display: flex;
    flex-basis: 100%;
    justify-content: flex-end;

    .ms-Dropdown {
      width: 100%;
      max-width: 11rem;
    }
  }
}

.cr-sub-section {
  margin: 0;

  .cr-sub-section-inner {
    padding: 0;
    background-color: transparent;
    box-shadow: none;

    .cr-sub-section-inner-title {
      margin-bottom: 1.5rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #bbb;
      font-weight: $font-weight-bold;
      font-size: $font-size-lg;
    }
  }

  &.--cont {
    margin-top: -1px !important;
  }

  &.--max-width-lg {
    .cr-sub-section-inner-content {
      max-width: 740px;
    }
  }

  &.--max-width-md {
    .cr-sub-section-inner-content {
      max-width: 550px;
    }
  }
}

.cr-section-list {
  margin: 0;
  padding: 0;
  list-style: none;

  &.--with-spacer {
    margin-bottom: 1.5rem;
  }

  li {
    padding: 2px 0;
    line-height: 150%;
  }
}

.cr-section-inner-content {
  .cr-section:last-child {
    padding-bottom: 1px;
  }

  &.--footer-margin {
    margin-bottom: 30px;
  }

  &.--graph-scroll {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.cr-section-content {
  word-break: break-all;
}

.cr-section-img-container {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0;
  border: 1px solid #ddd;
  background: repeating-linear-gradient(-45deg, #fff, #eee 3px, #fff 3px, #fff 6px);
  box-shadow: $app-content-box-shadow;

  .cr-section-img {
    width: auto;
    max-width: 100%;
    margin: 0 auto;
  }
}
