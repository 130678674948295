@import "variables.scss";

.app-auth-password {
  display: table;
  width: 100%;
  min-width: 320px;
  height: 100%;
  border-collapse: separate;

  .app-auth-password-wrapper {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    padding: 50px 0;

    .app-auth-password-header {
      max-width: 520px;
      margin: 0 auto;
      text-align: center;

      .logo {
        $-logo-height: 15px;
        $-logo-size: 33px;
  
        position: relative;
        box-sizing: content-box;
        height: $-logo-height;
        margin: calc(#{$-logo-size} * 2) 0 2rem 0;
        background: url('../image/logo_maven_text_blue.png') bottom left / auto 100% no-repeat;
        background-position: bottom center;
        line-height: $-logo-height;
        text-indent: -9999px;
        transition: all ease-in-out 0.2s;

  
        &:before {
          content: '';
          position: absolute;
          top: -10px;
          left: 33%;
          display: block;
          width: $-logo-size;
          height: $-logo-size;
          margin-left: calc(#{$-logo-size} / -2);
          background: url('../image/logo_maven_icon_circle_blue.png') center center / 100% auto no-repeat;
          font-size: $font-size-5xl;
        }
      }
    }

    .app-auth-password-content {
      position: relative;
      box-sizing: border-box;
      max-width: 580px;
      min-height: 520px;
      margin: 40px auto;
      padding: 58px 69px 139px;
      border: 1px solid #e5e5e5;
      border-radius: 2px;
      background-color: $app-content-box-background;

      .title {
        margin-bottom: 2rem;
        color: #252525;
        font-weight: normal;
        font-size: 24px;
        line-height: 34px;
        letter-spacing: -1px;
      }

      .desc {
        margin-bottom: 4rem;
        text-align: left;
        word-break: keep-all;
      }
    }

    .copyright {
      position: absolute;
      width: 100%;
      color: lighten(#000, 80%);
      font-weight: normal;
      font-size: $font-size-sm;
      text-align: center;

      a {
        color: lighten(#000, 50%);
      }

      .privacy-policy {
        padding-bottom: 1rem;
        font-size: $font-size-base;
      }
    }
  }
}
