@import "variables.scss";

.flax {
  &.fx-notification {
    box-shadow: 0 0 1.2rem rgba(#000, 0.15) !important;
  }

  &.fx-notification-container {
    z-index: 1000001 !important;
  }
}

.flax.fx-guard.--auto-reload {
  opacity: 1 !important;
}

.app-wrapper {
  height: 100%;

  > .flax.fx-guard {
    height: 100%;

    &.--loading {
      opacity: 1;
    }

    .fx-guard-error {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 1rem;

      &:before {
        content: "lightbulb";
        display: block;
        padding-bottom: 3rem;
        border: none;
        color: $app-text-color;
        font-size: 10rem;
        font-family: "Material Icons Outlined";
        line-height: 100%;
        text-align: center;
      }
    }
  }

  .app-main {
    .app-content {
      > .fx-guard {
        > .fx-guard-error {
          min-height: 60vh;
        }
      }

      .flax.fx-guard {
        .fx-guard-loader {
          &:before,
          &:after {
            width: 34px;
            height: 34px;
            margin-top: -17px;
            margin-left: -17px;
            border-width: 3px;
          }

          &:before {
            border-right-color: #259ac5;
            border-bottom-color: #259ac5;
          }
        }
      }
    }
  }

  .cw-paginatable-items {
    > .fx-guard {
      > .fx-guard-error {
        min-height: 50vh;
      }
    }
  }

  .app-layout {
    height: 100%;

    .app-fullpage {
      height: 100%;

      .app-content.message {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        i {
          display: block;
          padding-bottom: 3rem;
          border: none;
          color: $app-text-color;
          font-style: normal;
          font-size: 10rem;
          font-family: "Material Icons Outlined";
          line-height: 100%;
          text-align: center;
        }

        p {
          margin: 0;
          margin-bottom: 1rem;
          font-size: 1rem;
        }

        a {
          padding: 0.7rem 1.2rem;
          border-radius: 1.4rem;
          background: $color-primary;
          color: #fff;
          font-weight: 0.9rem;
          text-decoration: none;

          &:hover {
            background: darken($color-primary, 5%);
          }

          &:active {
            background: darken($color-primary, 8%);
          }
        }
      }
    }

    .app-header {
      position: fixed;
      top: 0;
      right: 0;
      left: $app-sidebar-width;
      z-index: 1;
      background: $app-content-background;
      box-shadow: 0 0 0.6rem $app-content-background;
      transition: left ease-in-out $app-sidebar-transition-duration;
    }

    .app-main {
      position: relative;
      margin-left: $app-sidebar-width;
      transition: margin-left ease-in-out $app-sidebar-transition-duration;

      .app-content {
        position: relative;
        z-index: 0;
        -webkit-overflow-scrolling: touch;
        min-height: calc(100vh - #{$app-footer-height});
        padding: calc(#{$app-content-padding} + #{$app-header-height}) $app-content-padding calc(#{$app-content-padding-narrow} / 3)
          $app-content-padding;
        background: $app-content-background;
        transition: all ease-in-out $app-sidebar-transition-duration;

        @media (max-width: $break-md) {
          padding: calc(#{$app-content-padding-narrow} + #{$app-header-height}) $app-content-padding-narrow
            calc(#{$app-content-padding-narrow} / 4) $app-content-padding-narrow;
        }

        .cr-footer-bar {
          left: $app-sidebar-width;
          padding: 0 $app-content-padding;

          @media (max-width: $break-md) {
            padding: 0 $app-content-padding-narrow;
          }
        }

        .bordered {
          padding: $app-content-box-padding;
          border: $app-content-box-border;
          border-radius: $app-content-box-radius;
          background: $app-content-box-background;
          color: $app-content-box-color;
          box-shadow: $app-content-box-shadow;

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: darken($app-content-box-color, 15%);
          }
        }
      }

      .app-footer {
        height: $app-footer-height;
        margin-bottom: 0;
        padding: 0 $app-content-padding;
        font-weight: $font-weight-light;
        font-size: $font-size-xs;
        line-height: $app-footer-height;
        text-align: right;
        text-transform: uppercase;
        transition: padding ease-in-out $app-sidebar-transition-duration;

        @media (max-width: $break-xl) {
          padding: 0 $app-content-padding-narrow;

          .privacy-policy {
            font-size: $font-size-sm;
          }
        }

        @media (max-width: $break-lg) {
          > div {
            justify-content: center;
          }

          .slogan {
            display: none;
          }

          p {
            display: block;
            float: none !important;
            text-align: center;

            .privacy-policy {
              font-size: $font-size-sm;
            }
          }
        }

        *::-webkit-scrollbar {
          width: 3px;
          height: 3px;
        }

        *::-webkit-scrollbar-track {
          border-radius: $app-form-border-radius;
          background-color: #e4e4e4;
        }

        *::-webkit-scrollbar-thumb {
          border-radius: $app-form-border-radius;
          background-color: #999;
        }

        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          overflow-y: hidden;
          height: $app-footer-height;
          white-space: nowrap;

          > * {
            flex-shrink: 0;
          }

          .slogan {
            ul {
              display: flex;
              margin: 0;
              padding: 0;
              list-style: none;

              li {
                margin: 0 2px;

                &:before {
                  content: '/';
                  display: inline-block;
                  margin-right: 4px;
                  color: #CCC;
                  font-weight: bold;
                  font-size: $font-size-xs;
                }

                &:first-child {
                  margin-left: 0;

                  &:before {
                    display: none;
                  }
                }

                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }

        .slogan {
          float: left;
          font-weight: $font-weight-normal;
        }

        p {
          float: right;
          margin: 0;
          padding: 0;
        }

        &:after {
          clear: both;
        }

        .pg-footer {
          justify-content: center;

          @media (max-width: 1400px) {
            justify-content: left;
          }

          .slogan {
            display: block;
          }

          > p {
            display: none;
          }
        }
      }
    }
  }

  .suspense-loading {
    position: fixed;
    width: 100%;
    height: 100%;
    line-height: 100%;
    text-align: center;
    text-indent: -9999px;

    .loading {
      @-webkit-keyframes load7 {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }
      @keyframes load7 {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200px;
      height: 200px;
      margin-top: -100px;
      margin-left: -100px;

      .loader,
      .loader:before,
      .loader:after {
        width: 2.5em;
        height: 2.5em;
        border-radius: 50%;
        -webkit-animation: load7 1.8s infinite ease-in-out;
        animation: load7 1.8s infinite ease-in-out;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }
      .loader {
        position: relative;
        margin: 80px auto;
        color: fade-out(#1b95e0, 0.3);
        font-size: 8px;
        text-indent: -9999em;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
      }
      .loader:before,
      .loader:after {
        content: "";
        position: absolute;
        top: 0;
      }
      .loader:before {
        left: -3.5em;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
      }
      .loader:after {
        left: 3.5em;
      }
    }
  }
}
