.cr-radio-buttons {
  .cr-radio-button {
    label {
      display: inline-block !important;
    }

    em {
      position: relative;
      top: 6px;
      left: 5px;
      padding: 3px 0;
      font-style: normal;
      font-size: $font-size-base;
      pointer-events: none;
    }
  }

  .ms-ChoiceField-wrapper {
    width: 100%;
  }

  .ms-ChoiceFieldGroup-flexContainer {
    .ms-ChoiceField {
      margin-top: 3px;

      label {
        color: $color-gray-800;

        &.is-checked {
          color: $color-gray-900;
          font-weight: 500;
        }
      }

      &:hover {
        .ms-ChoiceField-field {
          &:after {
            content: "";
            width: 10px;
            height: 10px;
            background-color: #777;
            transition-property: background-color;
          }
        }
      }
    }

    .ms-ChoiceField:first-child {
      margin-top: 0;
    }

    .ms-ChoiceField-input {
      cursor: pointer;
    }

    .ms-ChoiceFieldLabel {
      padding-left: 22px;
      line-height: 160%;
    }

    .ms-ChoiceField-field {
      display: block;
      padding: 3px 0;
      word-break: keep-all;

      &:before {
        top: 6px;
        left: 1px !important;
        width: 14px;
        height: 14px;
        border-color: #ccc;
      }

      &.is-checked {
        &:before {
          border-color: $color-primary;
        }
      }

      &:after {
        top: 8px;
        left: 3px !important;
        border-color: $color-primary;
      }
    }
  }

  &.direction--horizontal {
    .ms-ChoiceFieldGroup-flexContainer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-left: -0.2rem;

      .ms-ChoiceField {
        margin-top: 0;
        margin-right: 0.2rem;
        margin-left: 0.2rem;

        .ms-ChoiceField-field {
          padding-right: 8px;
          padding-left: 8px;

          &:before {
            left: 9px !important;
          }

          &:after {
            left: 11px !important;
          }
        }
      }
    }
  }

  &.direction--vertical {
    .ms-ChoiceFieldGroup-flexContainer {
      display: flex;
      flex-direction: column;

      .ms-ChoiceField {
        margin-left: 0 !important;
      }
    }
  }

  &.align--center {
    .ms-ChoiceFieldGroup-flexContainer {
      justify-content: center;
    }
  }
}
