.cr-toggle {
  .ms-Toggle-background {
    width: 45px;
    height: 25px;
    border-color: #ccc;
    border-radius: 13px;
    transition: all ease-in-out 0.2s;

    .ms-Toggle-thumb {
      width: 17px;
      height: 17px;
      transition: all ease-in-out 0.2s;
    }

    &:hover {
      border-color: #aaa;

      .ms-Toggle-thumb {
        background: #333;
      }
    }
  }
  
  .ms-Toggle-stateText {
    display: flex;
    align-items: center;
  }

  .is-checked {
    .ms-Toggle-background {
      border-color: $color-primary;
      background-color: $color-primary;

      &:hover {
        .ms-Toggle-thumb {
          background-color: #eee;
        }
      }
    }
  }
}
