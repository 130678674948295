.--vertical-size-medium {
  max-height: 70vh;
  overflow-y: auto;
}

.cr-dialog {
  $-button-spacing: 0.15rem;

  @media (max-width: 480px) {
    .ms-Dialog-main {
      width: 100%;

      .ms-Dialog-actions {
        .ms-Button {
          padding-right: 8px;
          padding-left: 8px;
        }
      }
    }
  }

  @media (max-width: 360px) {
    .ms-Dialog-main {
      .ms-Dialog-actions {
        .ms-Button {
          padding-right: 4px;
          padding-left: 4px;
        }
      }
    }
  }

  .ms-Dialog-actionsRight {
  }

  .ms-Dialog-lgHeader {
    transition: all ease-in-out 0.2s;
  }

  .--size-wider {
    width: 750px;
    max-width: 100%;
  }

  .--size-wide {
    width: 450px;
    max-width: 100%;
  }

  .--size-base {
    width: 350px;
    max-width: 100%;
  }

  .--size-narrow {
    width: 300px;
    max-width: 100%;
  }

  .wide-dialog {
    width: 650px;
    max-width: 100%;
  }

  .ms-Dialog-main {
    min-height: auto;
  }

  .ms-Dialog-lgHeader {
    border-top-color: $color-primary;
  }

  .ms-Dialog-title {
    margin: 16px 24px 20px 24px;
    padding: 0;
    color: $color-primary;
  }

  .ms-Dialog-header-sub {
    margin: 0.4rem 0 0 0;
    color: $color-gray-800;
    font-size: $font-size-base;
  }

  .ms-Dialog-content {
    line-height: 155%;
    .space-input {
      margin-top: 1rem;
    }
  }

  .ms-Dialog-actions {
    margin-top: 25px;
  }

  .ms-Dialog-action {
    margin: 0 $-button-spacing;

    .ms-Button {
      &.--icon {
        .ms-Icon {
          position: relative;
          top: 2px;
          margin-top: -5px;
        }

        &.--icon-ActivateOrders .ms-Icon {
        }

        &.--icon-CloudDownload .ms-Icon {
          top: 4px;
          margin-top: -11px;
          font-size: 16px;
        }

        &.--icon-Add .ms-Icon {
        }
      }
    }
  }

  .misc-btn {
    float: left;
    margin: 0 0 0 calc(#{$-button-spacing} * -1);

    .cr-button {
      margin: 0 $-button-spacing;
    }

    p {
      font-size: $font-size-sm;
      color: #777;
    }
  }

  .img-label-btn-container {
    display: flex;
  }

  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border: 1px solid #ccc;
    background: repeating-linear-gradient(-45deg, #fff, #eee 3px, #fff 3px, #fff 6px);

    img {
      max-width: 100%;
    }
  }

  .--text-size-medium {
    .ms-Dialog-title {
      font-size: $font-size-4xl;
    }

    .ms-Dialog-header-sub {
      font-size: $font-size-3xl;
    }

    .ms-Dialog-content {
      font-size: $font-size-2xl;
    }
    .ms-Dialog-action {
      > button {
        font-size: $font-size-xl;
      }
    }
  }
  .--text-size-large {
    .ms-Dialog-title {
      font-size: $font-size-6xl;
    }

    .ms-Dialog-header-sub {
      font-size: $font-size-5xl;
    }

    .ms-Dialog-content {
      font-size: $font-size-4xl;
    }
    .ms-Dialog-action {
      > button {
        font-size: $font-size-3xl;
      }
    }
  }

  .flax.fx-guard {
    .fx-guard-loader {
      &:before,
      &:after {
        width: 34px;
        height: 34px;
        margin-top: -17px;
        margin-left: -17px;
        border-width: 3px;
      }

      &:before {
        border-right-color: #259ac5;
        border-bottom-color: #259ac5;
      }
    }
  }

  &.header-sticky {
    .ms-Dialog-lgHeader {
      overflow-y: clip !important;
    }

    .ms-Dialog-header {
      position: sticky;
      top: 0;
      z-index: 1;
      background: #fff;
      padding: 1px;
    }
  }

  &.footer-sticky {
    .ms-Dialog-lgHeader {
      overflow-y: clip !important;
    }

    .ms-Dialog-actions {
      position: sticky;
      bottom: 0;
      z-index: 1;
      background: #fff;
      margin: 0px;
      padding-top: 25px;
      padding-bottom: 25px;
    }

    .ms-Dialog-inner {
      padding-bottom: 0px;
    }
  }
}
