.al-dropdown-wrap {
  position: relative;
  display: inline-block;
  max-width: 100%;

  &.full-width {
    display: block;

    .al-dropdown-toggle {
      display: block;
      width: 100%;
      text-align: left;
    }
  }

  .al-dropdown-toggle {
    padding-right: 2.2rem;
    cursor: pointer;

    &:after {
      content: "keyboard_arrow_down";
      position: absolute;
      top: 50%;
      right: 0.85rem;
      display: inline-block;
      vertical-align: middle;
      margin-top: -0.42rem;
      padding: 0;
      border: none;
      font-family: "Material Icons";
      transition: all ease-in-out 0.15s;
    }
  }

  &.show {
    .al-dropdown-toggle {
      border-color: mix($app-form-border-color, $app-color-focus) !important;

      &:after {
        transform: rotate(180deg);
      }
    }
  }

  .al-dropdown-menu {
    position: absolute;
    top: -1rem !important;
    left: 0;
    z-index: 2;
    display: block;
    min-width: 100%;
    max-width: 100%;
    margin: 0.6rem 0 0 0;
    padding: 0.3rem 0 0.3rem 0;
    border: $app-form-dropdown-border;
    border-radius: $app-form-border-radius;
    background: $app-form-background;
    box-shadow: $app-form-dropdown-shadow;
    opacity: 0;
    visibility: hidden;
    transition: all ease-in-out 0.15s;
    transform: translate3d(0, 2.3rem, 0px) !important;

    &.show {
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 2.9rem, 0px) !important;
    }

    &:focus {
      outline: none;
    }

    &:before {
      content: "";
      position: absolute;
      top: -6px;
      right: 1rem;
      z-index: 0;
      display: block;
      width: 10px;
      height: 10px;
      border-top: $app-form-dropdown-border;
      border-left: $app-form-dropdown-border;
      background: $app-form-background;
      transform: rotate(45deg);
    }

    .al-dropdown-item {
      position: relative;
      z-index: 1;
      display: block;
      overflow: hidden;
      width: 100%;
      padding: 0.6rem 1rem;
      border: none;
      background: transparent;
      text-align: left;
      text-overflow: ellipsis;
      cursor: pointer;

      &:focus {
        outline-color: mix($app-form-border-color, $app-color-focus);
      }

      &:active {
        background: $app-form-dropdown-item-background-hover;
        color: $app-form-color;
      }

      &:hover {
        background: $app-form-dropdown-item-background-hover;
      }

      &.disabled {
        color: lighten($app-form-color, 60%);
      }
    }

    .al-dropdown-item {
      height: 2rem;
      font-size: $font-size-base;
      line-height: 1;
    }

    :first-child {
      &.dropdown-header {
        margin-top: 0;
      }
    }
  }
}
