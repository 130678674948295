.cr-nav {
  $-nav-height: 38px;
  $-nav-item-height: 28px;
  $-nav-item-left: 29px;
  $-nav-icon-left: 6px;

  .cr-nav-group-header {
    &.--badge {
      position: relative;

      .--alarm {
        position: absolute;
        right: 4px;
        top: 50%;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        font-weight: bold;
        font-size: 9px;
        line-height: 20px;
        text-align: center;
        text-indent: 1px;
        color: #ed214b;
        transform: translate3d(0, -50%, 0);
        background: rgba(237, 33, 75, 0.15);
      }

      .ms-Nav-chevronButton {
        padding-right: 24px;
      }
    }
  }

  .cr-nav-wrapped {
    &.--is-not-available {
      color: #a19f9d;
    }

    &:not(.--with-badge) {
      margin-right: -20px;
    }

    .cr-nav-status {
      $-size: 12px;
      $-size-badge: 20px;

      position: absolute;
      width: $-size;
      text-indent: -9999px;

      &.--saved {
        top: 4px;
        bottom: 4px;
        left: 11px;
        z-index: 2;
        height: auto;
        border: 1px solid #999;
        background: transparent;
        opacity: 0.4;

        &:before,
        &:after {
          content: "";
          position: absolute;
          top: 23%;
          right: -1px;
          left: -1px;
          z-index: 2;
          box-sizing: border-box;
          height: 10px;
          border: 1px solid #999;
          background: transparent;
        }

        &:after {
          top: 50%;
        }
      }

      &.--verified {
        top: 4px;
        left: 11px;
        z-index: 1;
        height: 5px;
        background: $color-sdv;
      }

      &.--frozen {
        top: 9px;
        left: 11px;
        z-index: 1;
        height: 6px;
        background: $color-freeze;
      }

      &.--signed {
        bottom: 9px;
        left: 11px;
        z-index: 1;
        height: 5px;
        background: $color-sign;
      }

      &.--locked {
        bottom: 4px;
        left: 11px;
        z-index: 1;
        height: 6px;
        background: $color-lock;
      }

      &.--badge {
        position: absolute;
        top: 50%;
        right: 4px;
        width: $-size-badge;
        height: $-size-badge;
        margin-top: calc(#{$-size-badge} / -2);
      }

      &.--badge > div {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #999;
        color: #fff;
        font-weight: bold;
        font-size: 9px;
        line-height: $-size-badge;
        text-align: center;
        text-indent: 1px;
      }

      &.--badge {
        .--warning {
          background-color: yellow;
          color: #000;
        }

        .--alarm {
          background: #ed214b;
          color: #fff;
        }
      }

      &.--badge.--notice.--alarm .--notice,
      &.--badge.--notice.--warning .--notice {
        position: absolute;
        top: -3px;
        right: -3px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        text-indent: -9999px;
      }
    }
  }

  .ms-Nav-group {
    .ms-Nav-chevronButton {
      height: $-nav-height;
      margin-top: 0;
      padding-left: calc(#{$-nav-item-left} + #{$-nav-icon-left});
      font-size: $font-size-sm;
      line-height: $-nav-height;

      i {
        left: calc(#{$-nav-icon-left} + 7px);
        height: $-nav-height;
        font-size: 8px;
        line-height: $-nav-height;
      }
    }

    .ms-Nav-navItem {
      .ms-Nav-compositeLink {
        .ms-Nav-link {
          height: $-nav-height;
          margin: 0;
          padding-left: calc(#{$-nav-item-left} + #{$-nav-icon-left});
          border-bottom: 1px solid #edebe9;
          border-radius: 0;
          line-height: $-nav-height;

          &:active {
            padding-left: calc(#{$-nav-item-left} + #{$-nav-icon-left});
          }

          .ms-Nav-linkText {
            margin: 0;
          }

          .--color-red {
            color: $color-red-600;
          }

          i {
            position: absolute;
            top: 0;
            left: $-nav-icon-left;
          }

          .ms-Button-flexContainer {
            position: static;

            i {
              height: $-nav-height;
              color: #3e494e;
              font-size: $font-size-base;
              line-height: $-nav-height;
            }
          }
        }

        &.is-selected {
          .ms-Nav-link {
            &:after {
              top: 4px;
              bottom: 4px;
              border-color: $color-primary;
            }
          }
        }
      }

      .ms-Button {
        height: $-nav-item-height;
        font-size: $font-size-sm;
        line-height: $-nav-item-height;
      }
    }

    /* Child Object */
    .ms-Nav-chevronButton + .ms-Nav-groupContent,
    .cr-nav-group-header + .ms-Nav-groupContent {
      .ms-Nav-navItem {
        .ms-Nav-compositeLink {
          .ms-Nav-link {
            height: $-nav-item-height;
            border: none;
            line-height: $-nav-item-height;

            i {
              left: calc(#{$-nav-icon-left} + 2.5px);
              display: none;
              height: $-nav-item-height;
              color: lighten(#3e494e, 30%);
              font-size: 8px;
              line-height: $-nav-item-height;
            }
          }
        }
      }
    }

    .ms-Nav-groupContent {
      margin-bottom: 0.2rem;
      animation: none;
    }
  }

  &.--wrap-text {
    .ms-Nav-group {
      .ms-Nav-groupContent {
        .ms-Nav-navItem {
          .ms-Nav-link {
            height: auto !important;
            min-height: 28px;
          }

          .ms-Nav-linkText {
            overflow: unset;
            padding: 10px 0 9px 0;
            line-height: 140%;
            text-overflow: unset;
            white-space: normal;
            word-break: keep-all;
          }
        }
      }

      .ms-Nav-chevronButton + .ms-Nav-groupContent,
      .cr-nav-group-header + .ms-Nav-groupContent {
        .ms-Nav-navItem {
          .ms-Nav-compositeLink {
            .ms-Button-flexContainer {
              padding-top: 6px;
              padding-bottom: 5px;
            }

            .ms-Nav-linkText {
              padding-top: 0;
              padding-bottom: 0;
            }
          }
        }
      }
    }

    .cr-nav-wrapped {
      .cr-nav-status {
        &.--saved {
          top: 50%;
          bottom: unset;
          height: 20px;
          margin-top: -10px;
        }

        &.--verified {
          top: 50%;
          margin-top: -10px;
        }

        &.--frozen {
          top: 50%;
          margin-top: -5px;
        }

        &.--signed {
          top: 50%;
          margin-top: 0px;
        }

        &.--locked {
          top: 50%;
          margin-top: 4px;
        }
      }
    }
  }
}
