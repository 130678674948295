@import "variables.scss";
/* stylelint-disable order-media */

$app-hybrid-header-icon-height: 145px;
$app-hybrid-header-icon-height-spacing: -7px;
$app-hybrid-max-width: 450px;
$app-hybrid-header-small-width: 30px;
$app-hybrid-title-timer-width: 75px;

.app-hybrid-header-panel {
  position: relative;
  display: inline-block;
  margin-left: 25px;
  top: 3px;
  color: #259ac5;

  &:before {
    content: "";
    position: absolute;
    top: -3px;
    left: -25px;
    display: inline-block;
    width: 20px;
    height: 20px;
    font-size: large;
    background: url("../image/logo_maven_icon_circle_blue.png") center center / auto 20px no-repeat;
  }
}

/* Header */
.app-hybrid-wrapper {
  input,
  button {
    -webkit-border-radius: none;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
  }

  &:before {
    content: "";
    position: fixed;
    top: 0;
    left: 50%;
    z-index: 0;
    width: $app-hybrid-max-width;
    height: $app-hybrid-header-height;
    margin-left: calc(#{$app-hybrid-max-width} / -2);
    background: $color-primary;
  }

  .maven-cdms .app-hybrid-header-title strong.app-name {
    width: 118px;
    background: url("../image/logo_maven_cdms_text_white.png") right center / auto 13px no-repeat;
  }

  .maven-builder .app-hybrid-header-title strong.app-name {
    width: 125px;
    background: url("../image/logo_maven_builder_text_white.png") right center / auto 13px no-repeat;
  }

  .maven-validator .app-hybrid-header-title strong.app-name {
    width: 113px;
    background: url("../image/logo_maven_validator_text_white.png") right center / auto 13px no-repeat;
  }

  .maven-ecoa .app-hybrid-header-title strong.app-name {
    width: 113px;
    background: url("../image/logo_maven_ecoa_text_white.png") right center / auto 13px no-repeat;
  }

  .app-hybrid-header {
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 3;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: $app-hybrid-header-height;
    background: $color-primary;
    box-shadow: 0 0 0.9rem rgba(0, 0, 0, 0.2);
    line-height: $app-hybrid-header-height;
    user-select: none;

    .app-hybrid-header-title {
      z-index: 2;
      display: flex;
      flex-basis: 100%;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background: $color-primary;
      color: $app-header-color;
      font-size: $font-size-lg;

      .app-hybrid-header-title-text {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .app-hybrid-header-title-bar {
        overflow: hidden;
        width: $app-hybrid-header-height;
        height: $app-hybrid-header-height;
      }

      strong.app-name {
        position: relative;
        display: inline-block;
        height: 20px;
        margin-left: 20px;
        text-indent: -9999px;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: -20px;
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url("../image/logo_maven_icon_circle_white.png") center center / auto 20px no-repeat;
        }
      }

      /* stylelint-disable-next-line */
      @media screen and (max-width: $app-hybrid-max-width) {
        .app-hybrid-header-title-bar {
          width: $app-hybrid-header-small-width;
        }

        strong.app-name {
          width: 80px;
          margin-left: 14px;
          background-size: contain;

          &:before {
            left: -14px;
            width: 10px;
            background-size: contain;
          }
        }
      }
    }
  }
}

.app-hybrid-layout.--with-header-icon {
  .app-hybrid-header {
    height: calc(#{$app-hybrid-header-height} + #{$app-hybrid-header-icon-height-spacing} + (#{$app-hybrid-header-icon-height} * 0.5));
    margin-bottom: calc(#{$app-hybrid-header-icon-height} / 2);
    padding-bottom: calc(#{$app-hybrid-header-icon-height-spacing} + (#{$app-hybrid-header-icon-height} * 0.5));

    .app-hybrid-header-icon {
      position: absolute;
      top: calc(#{$app-hybrid-header-height} + #{$app-hybrid-header-icon-height-spacing});
      height: $app-hybrid-header-icon-height;
      border-radius: 50%;
      background: $color-primary;
      box-shadow: 0 0 0.9rem rgba(0, 0, 0, 0.2);

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: -40px;
        left: -40px;
        z-index: 0;
        height: calc(#{$app-hybrid-header-icon-height} * 0.5);
        background: $color-primary;
      }

      .ms-Icon {
        position: relative;
        z-index: 1;
        width: $app-hybrid-header-icon-height;
        height: $app-hybrid-header-icon-height;
        color: $app-header-color;
        font-size: 73px;
        line-height: $app-hybrid-header-icon-height;
        text-align: center;

        &.--icon-BirthdayCake {
          padding-left: 5px;
          line-height: 125px;
        }

        &.--icon-DocumentApproval {
          padding-left: 10px;
        }
      }
    }
  }
}

/* Wrapper */
.app-hybrid-wrapper {
  position: relative;
  max-width: $app-hybrid-max-width;
  margin: auto;
  background: $app-content-background;
  box-shadow: 0 0 0.9rem rgba(0, 0, 0, 0.2);

  .app-hybrid-layout {
    .app-hybrid-section {
      .flax.fx-guard {
        .fx-guard-loading {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: calc(70vh - #{$app-hybrid-header-height});
        }

        .fx-guard-loader {
          &:before,
          &:after {
            width: 34px;
            height: 34px;
            margin-top: -17px;
            margin-left: -17px;
            border-width: 3px;
          }

          &:before {
            border-right-color: #259ac5;
            border-bottom-color: #259ac5;
          }
        }
      }
    }
  }
}

.app-hybrid-footer {
  position: sticky;
  bottom: 0;
  height: $app-hybrid-footer-height;
  line-height: $app-hybrid-footer-height;

  &.app-hybrid-footer-dummy {
    display: none;
  }

  .app-hybrid-footer-copyright {
    &.--position-fixed {
      position: fixed;
      bottom: 0;
      width: 100%;
      max-width: $app-hybrid-max-width;
      height: $app-hybrid-footer-height;
      background: $app-content-background;
      font-size: $font-size-xs;
      line-height: $app-hybrid-footer-height;
      text-align: center;
      user-select: none;
    }
  }
}

/* Content */
.app-hybrid-main {
  z-index: 1;

  .app-hybrid-section {
    min-height: calc(100vh - #{$app-hybrid-header-height});
    padding: 0;

    .app-hybrid-content-title-bar-button.--right {
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 5;
        width: 1px;
        height: 14px;
        background: #fff;
        transform: translate3d(0, -50%, 0);
      }
    }
  }
}

/* Content Header */
.app-hybrid-layout {
  .app-hybrid-content-title-bar {
    position: fixed;
    top: 0;
    left: calc(50% + 5px);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 150px;
    height: $app-hybrid-header-height;
    background: $color-primary;
    color: $app-header-color;
    line-height: 1.3;
    text-overflow: ellipsis;
    white-space: normal;
    user-select: none;
    transform: translate3d(-50%, 0, 0);

    @media screen and (max-width: $app-hybrid-max-width) {
      left: 50%;
      width: calc(100% - (#{$app-hybrid-header-small-width} * 3) - #{$app-hybrid-title-timer-width});
      min-width: 115px;
      max-width: 115px;
    }

    &.font--small {
      font-size: $font-size-base;
    }
    &.font--medium {
      font-size: $font-size-2xl;
    }
    &.font--large {
      font-size: $font-size-4xl;
    }

    .app-hybrid-content-title-timer {
      top: 0;
      left: calc(57% + #{$app-hybrid-header-height});
      z-index: 5;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 65px;
      max-width: 100%;
      height: $app-hybrid-header-height;
      margin-left: 10px;
      cursor: pointer;

      &:before {
        content: "access_time";
        display: inline-block;
        vertical-align: middle;
        color: $app-header-color;
        font-size: 1rem;
        font-family: "Material Icons Outlined";
      }

      > button {
        border: none;
        background: transparent;
        color: $app-header-color;
        outline: none;
        cursor: pointer;
      }

      > div {
        display: none;
      }

      &.font--small {
        > button {
          font-size: $font-size-base;
        }
      }
      &.font--medium {
        padding-left: 10px;
        > button {
          font-size: $font-size-2xl;
        }
      }
      &.font--large {
        padding-left: 15px;
        > button {
          font-size: $font-size-4xl;
        }
      }
    }
  }

  .app-hybrid-header-title-timer {
    top: 0;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    cursor: pointer;

    @media screen and (max-width: $app-hybrid-max-width) {
      left: 26px;
    }

    &:before {
      content: "access_time";
      display: inline-block;
      vertical-align: middle;
      color: $app-header-color;
      font-size: $font-size-base;
      font-family: "Material Icons Outlined";
    }

    &.--left-border::after {
      content: "";
      position: absolute;
      top: 50%;
      left: -10px;
      width: 1px;
      height: 13px;
      background: #fff;
      transform: translate3d(0, -50%, 0);
    }

    > button {
      border: none;
      background: transparent;
      color: $app-header-color;
      outline: none;
      cursor: pointer;
    }

    > div {
      display: none;
    }
  }

  .app-hybrid-content-title-bar-button {
    position: fixed;
    top: 0;
    z-index: 4;
    overflow: hidden;
    width: $app-hybrid-header-height;
    height: $app-hybrid-header-height;
    margin: 0;

    button {
      width: 100%;
      min-width: auto;
      max-width: auto;
      height: 100%;
      margin: 0;
      padding: 0;
      border-radius: 0;
      outline: none;
      line-height: $app-hybrid-header-height;

      i {
        padding-left: 1px;
        font-weight: bold;
        font-size: $font-size-base;

        &.--icon-Signout {
          padding-left: 7px;
        }

        &.--icon-ChromeBack {
          font-size: $font-size-xs;
        }
      }
    }

    /* stylelint-disable-next-line */
    @media screen and (max-width: $app-hybrid-max-width) {
      width: $app-hybrid-header-small-width;

      &.--left {
        left: 0;
        margin-left: 0;
      }

      &.--right {
        right: 0;
        width: auto;
        margin-right: 0.1rem;
      }

      button {
        margin-right: 0.2rem;
      }
    }
  }
}

.app-hybrid-popup {
  .ms-Dialog-main {
    overflow-y: hidden;
    width: 450px;
    max-width: 90%;
    height: 90%;

    .ms-Modal-scrollableContent {
      overflow-y: hidden;
      max-height: 100%;
    }

    .ms-Dialog-header {
      display: flex;
      justify-content: center;

      .app-hybrid-popup-title {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        margin-left: 1.2rem;
        padding: 0;
        font-weight: bolder;
        font-size: 25px;
        word-break: break-all;
      }

      .app-hybrid-popup-button-inner {
        position: absolute;
        top: 0;
        right: 20px;
        display: flex;

        .ms-Button {
          display: flex;
          justify-content: flex-end;
          margin-top: 0.3rem;
          margin-right: -10px;
          color: black;

          .ms-Button {
            display: flex;
            margin-top: 0.3rem;
            margin-right: -10px;
            color: black;
          }
        }
      }
    }

    .ms-Dialog-inner {
      overflow-y: scroll;
      height: calc(100vh - 32px - 110px);

      .ms-Dialog-content {
        .app-hybrid-popup-body {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin: 1rem 1.2rem;
          padding-bottom: 100px;
          word-break: break-all;

          > a {
            color: $app-text-color;
            text-decoration: underline;

            &.--color-gray {
              color: $color-gray-600;
            }
            &.--color-danger {
              color: $app-color-danger;
            }

            &.--bold {
              font-weight: bold;
            }

            > img {
              object-fit: contain;
              width: 100%;
            }
          }
          > img {
            object-fit: contain;
            width: 100%;
          }
        }
      }
    }
  }
}

/* Content Footer */
.app-hybrid-section {
  .app-hybrid-footer {
    position: fixed;
    bottom: 0;
    z-index: 1;
    display: flex;
    width: 100%;
    max-width: $app-hybrid-max-width;
    height: $app-hybrid-footer-height;
    background: $app-content-background;
    line-height: $app-hybrid-footer-height;
    text-align: center;
    user-select: none;

    > * {
      flex-basis: 100%;
      margin: 0;

      &.hb-button,
      &.hb-request-button {
        button {
          width: 100%;
          min-width: auto;
          max-width: 100%;
          height: $app-hybrid-footer-height;
          padding: 0;
          border-radius: 0;
          font-weight: bold;
          font-size: $font-size-base;
          text-transform: uppercase;

          i {
            font-size: 0.9em;
          }
        }
      }
    }

    .app-hybrid-popup-icon {
      position: absolute;
      top: -70px;
      right: 1px;
      max-width: 80%;
      max-height: 80%;
      margin-right: 10px;
      margin-bottom: 20px;
      color: #ff6c3e;
      font-size: 20px;
    }

    .hb-progress-bar {
      $-footer-progress-bar-height: 6px;

      position: absolute;
      top: calc(#{$-footer-progress-bar-height} * -1);
      left: 0;
      width: 100%;
      height: $-footer-progress-bar-height;

      strong {
        top: calc((#{$-footer-progress-bar-height} * -1) - 10px);
        height: $-footer-progress-bar-height;
        line-height: $-footer-progress-bar-height;
      }
    }
  }
}

.app-hybrid-layout.--with-footer-dummy {
  .app-hybrid-section {
    min-height: auto;
  }
}

.app-hybrid-layout.--with-header-icon {
  .app-hybrid-section {
    min-height: calc(70vh - #{$app-hybrid-header-height} - #{$app-hybrid-header-icon-height} - #{$app-hybrid-header-icon-height-spacing});

    .flax.fx-guard {
      .fx-guard-loading {
        min-height: calc(
          70vh - #{$app-hybrid-header-height} - #{$app-hybrid-header-icon-height} - #{$app-hybrid-header-icon-height-spacing}
        );
      }
    }
  }

  &.--with-footer-dummy {
    .app-hybrid-section {
      min-height: calc(
        70vh - #{$app-hybrid-header-height} - #{$app-hybrid-header-icon-height} - #{$app-hybrid-header-icon-height-spacing} - #{$app-hybrid-footer-height}
      );

      .flax.fx-guard {
        .fx-guard-loading {
          min-height: calc(
            70vh - #{$app-hybrid-header-height} - #{$app-hybrid-header-icon-height} - #{$app-hybrid-header-icon-height-spacing} - #{$app-hybrid-footer-height}
          );
        }
      }
    }
  }
}

/* Loader */
.app-hybrid-wrapper {
  width: 100%;
  min-height: 100%;

  .flax.fx-guard {
    height: 100%;

    &.--loading {
      opacity: 1;
    }

    .fx-guard-error {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: calc(70vh - #{$app-hybrid-header-height});
      font-size: 1rem;

      &:before {
        content: "lightbulb";
        display: block;
        padding-bottom: 3rem;
        border: none;
        color: $app-text-color;
        font-size: 10rem;
        font-family: "Material Icons Outlined";
        line-height: 100%;
        text-align: center;
      }
    }
  }

  .app-fullpage {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    .app-content.message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      i {
        display: block;
        padding-bottom: 3rem;
        border: none;
        color: $app-text-color;
        font-style: normal;
        font-size: 10rem;
        font-family: "Material Icons Outlined";
        line-height: 100%;
        text-align: center;
      }

      p {
        margin: 0;
        margin-bottom: 1rem;
        font-size: 1rem;
      }

      a {
        padding: 0.7rem 1.2rem;
        border-radius: 1.4rem;
        background: $color-primary;
        color: #fff;
        font-weight: 0.9rem;
        text-decoration: none;

        &:hover {
          background: darken($color-primary, 5%);
        }

        &:active {
          background: darken($color-primary, 8%);
        }
      }
    }
  }

  .suspense-loading {
    position: fixed;
    width: 100%;
    height: 100%;
    line-height: 100%;
    text-align: center;
    text-indent: -9999px;

    .loading {
      @-webkit-keyframes load7 {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }
      @keyframes load7 {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200px;
      height: 200px;
      margin-top: -100px;
      margin-left: -100px;

      .loader,
      .loader:before,
      .loader:after {
        width: 2.5em;
        height: 2.5em;
        border-radius: 50%;
        -webkit-animation: load7 1.8s infinite ease-in-out;
        animation: load7 1.8s infinite ease-in-out;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }
      .loader {
        position: relative;
        margin: 80px auto;
        color: fade-out(#1b95e0, 0.3);
        font-size: 8px;
        text-indent: -9999em;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
      }
      .loader:before,
      .loader:after {
        content: "";
        position: absolute;
        top: 0;
      }
      .loader:before {
        left: -3.5em;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
      }
      .loader:after {
        left: 3.5em;
      }
    }
  }
}
