.cr-min-width {
  overflow-x: auto;

  &.--full-height {
    box-shadow: $app-content-box-shadow;

    .cr-min-width-inner {
      overflow-y: auto;
      height: calc(100vh - 18rem);
    }

    &.--with-tab {
      .cr-min-width-inner {
        height: calc(100vh - 22.2rem);
      }
    }
  }

  &.--no-shadow {
    box-shadow: none;
  }

  .cr-min-width-inner {
    position: relative;
  }
}
