.cr-input-group {
  > hr {
    height: 1px;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    background: lighten($app-form-border-color, 5%);
    width: 100%;
    display: block;
  }

  > p {
    margin: 3px 0 0 0 !important;
    padding: 0 !important;
  }

  &.direction--vertical {
    > *:not(:first-child) {
      margin-top: 20px;
    }
    &.spacing--1 > *:not(:first-child) {
      margin-top: 1px;
    }
    &.spacing--2 > *:not(:first-child) {
      margin-top: 2px;
    }
    &.spacing--3 > *:not(:first-child) {
      margin-top: 3px;
    }
    &.spacing--4 > *:not(:first-child) {
      margin-top: 4px;
    }
    &.spacing--5 > *:not(:first-child) {
      margin-top: 5px;
    }
    &.spacing--6 > *:not(:first-child) {
      margin-top: 6px;
    }
    &.spacing--7 > *:not(:first-child) {
      margin-top: 7px;
    }
    &.spacing--8 > *:not(:first-child) {
      margin-top: 8px;
    }
    &.spacing--9 > *:not(:first-child) {
      margin-top: 9px;
    }
    &.spacing--10 > *:not(:first-child) {
      margin-top: 10px;
    }
    &.spacing--11 > *:not(:first-child) {
      margin-top: 11px;
    }
    &.spacing--12 > *:not(:first-child) {
      margin-top: 12px;
    }
    &.spacing--13 > *:not(:first-child) {
      margin-top: 13px;
    }
    &.spacing--14 > *:not(:first-child) {
      margin-top: 14px;
    }
    &.spacing--15 > *:not(:first-child) {
      margin-top: 15px;
    }
    &.spacing--16 > *:not(:first-child) {
      margin-top: 16px;
    }
    &.spacing--17 > *:not(:first-child) {
      margin-top: 17px;
    }
    &.spacing--18 > *:not(:first-child) {
      margin-top: 18px;
    }
    &.spacing--19 > *:not(:first-child) {
      margin-top: 19px;
    }
    &.spacing--20 > *:not(:first-child) {
      margin-top: 20px;
    }
  }

  &.direction--horizontal {
    align-items: center;
    &.align--start {
      align-items: start;
    }
    &.align--center {
      align-items: center;
    }
    &.align--end {
      align-items: end;
    }

    > * {
      flex-basis: 100%;
    }

    > .cr-input,
    > .cr-button {
      margin-left: 2px;

      &:first-child {
        margin-left: 0;
      }
    }

    > *:not(:first-child) {
      margin-left: 20px;
    }
    &.spacing--1 > *:not(:first-child) {
      margin-left: 1px;
    }
    &.spacing--2 > *:not(:first-child) {
      margin-left: 2px;
    }
    &.spacing--3 > *:not(:first-child) {
      margin-left: 3px;
    }
    &.spacing--4 > *:not(:first-child) {
      margin-left: 4px;
    }
    &.spacing--5 > *:not(:first-child) {
      margin-left: 5px;
    }
    &.spacing--6 > *:not(:first-child) {
      margin-left: 6px;
    }
    &.spacing--7 > *:not(:first-child) {
      margin-left: 7px;
    }
    &.spacing--8 > *:not(:first-child) {
      margin-left: 8px;
    }
    &.spacing--9 > *:not(:first-child) {
      margin-left: 9px;
    }
    &.spacing--10 > *:not(:first-child) {
      margin-left: 10px;
    }
    &.spacing--11 > *:not(:first-child) {
      margin-left: 11px;
    }
    &.spacing--12 > *:not(:first-child) {
      margin-left: 12px;
    }
    &.spacing--13 > *:not(:first-child) {
      margin-left: 13px;
    }
    &.spacing--14 > *:not(:first-child) {
      margin-left: 14px;
    }
    &.spacing--15 > *:not(:first-child) {
      margin-left: 15px;
    }
    &.spacing--16 > *:not(:first-child) {
      margin-left: 16px;
    }
    &.spacing--17 > *:not(:first-child) {
      margin-left: 17px;
    }
    &.spacing--18 > *:not(:first-child) {
      margin-left: 18px;
    }
    &.spacing--19 > *:not(:first-child) {
      margin-left: 19px;
    }
    &.spacing--20 > *:not(:first-child) {
      margin-left: 20px;
    }

    > .cr-button {
      flex-basis: auto;
    }
  }
}
