.cr-collapsible-item {
  border-bottom: 1px solid #d2cccc;

  .question {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    cursor: pointer;
    transition: background-color 0.25s ease;

    &:hover {
      background-color: darken($app-content-background, 3%);
    }
    &.is-active {
      background-color: lighten($color-primary, 50%);
    }

    .q-icon {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      margin-right: 20px;
      border-radius: 50%;
      background-color: #f2f2f2;
      font-weight: 500;
      font-size: 15px;
      line-height: 1;

      &.is-active {
        background-color: $color-primary;
        color: white;
      }
    }

    .content {
      width: 100%;
      font-weight: 300;
      font-size: 15px;
      text-align: left;
    }

    .arrow-icon {
      float: right;
      margin-top: 3px;
    }
  }

  .answer {
    padding: 20px 10px;
    background-color: #fafafa;
    white-space: pre-line;

    .content {
      font-weight: 300;
      font-size: 15px;
      text-align: left;
    }
  }
}
