.cr-button {
  transition: opacity 0.2s ease-in-out;

  .material-icons-outlined {
    margin-right: 4px;
    margin-bottom: 1px;
    font-size: 15px;
  }

  &.size--square {
    min-width: 10px !important;
  }

  &.size--normal {
    min-width: 50px !important;
  }

  &.size--wide {
    min-width: 100px !important;
  }

  &.thickness--slim {
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &.thickness--normal {
  }

  &.thickness--thick {
    height: auto;
    padding-top: 13px;
    padding-bottom: 13px;
  }

  &.ms-Button {
    border-color: #ccc;

    .ms-Button-flexContainer {
      .ms-Icon {
        position: relative;
        margin-right: 5px;
        font-size: 13px;
      }

      .ms-Button-textContainer ~ .ms-Icon {
        top: 0;
        margin-top: 0;
        margin-right: -1px;
        font-size: 11px;
      }

      .ms-Icon {
        &.ms-Button-menuIcon {
          top: 1px !important;
          margin-top: 0 !important;
          font-weight: bold !important;
          font-size: 9px !important;
          transition: all ease-in-out 0.2s;

          &.is-expanded {
            top: -1px !important;
            transform: rotate(180deg);
          }
        }
      }

      i:first-child {
      }

      i:last-child {
        margin-left: 5px;
      }
    }

    &.--icon-Add .ms-Icon {
      font-weight: bold;
      font-size: 11px;
    }
  }

  &:disabled,
  &.state--disabled {
    opacity: 0.65;
  }

  &.primary {
    border-color: $color-primary;
    background-color: $color-primary;

    &:hover {
      border-color: darken($color-primary, 3%);
      background-color: darken($color-primary, 3%);
    }

    &:active {
      border-color: darken($color-primary, 5%);
      background-color: darken($color-primary, 5%);
    }

    span,
    .ms-Icon,
    .ms-Button-label {
      color: #ffffff;
    }
  }

  &.danger {
    border-color: $app-color-danger;
    background-color: $app-color-danger;

    &:hover {
      border-color: darken($app-color-danger, 3%);
      background-color: darken($app-color-danger, 3%);
    }

    &:active {
      border-color: darken($app-color-danger, 5%);
      background-color: darken($app-color-danger, 5%);
    }

    span,
    .ms-Icon,
    .ms-Button-label {
      color: #ffffff;
    }
  }

  &.success {
    border-color: $app-color-success;
    background-color: $app-color-success;

    &:hover {
      border-color: darken($app-color-success, 3%);
      background-color: darken($app-color-success, 3%);
    }

    &:active {
      border-color: darken($app-color-success, 5%);
      background-color: darken($app-color-success, 5%);
    }

    span,
    .ms-Icon,
    .ms-Button-label {
      color: #ffffff;
    }
  }

  &.warning {
    border-color: $app-color-warning;
    background-color: $app-color-warning;

    &:hover {
      border-color: darken($app-color-warning, 3%);
      background-color: darken($app-color-warning, 3%);
    }

    &:active {
      border-color: darken($app-color-warning, 5%);
      background-color: darken($app-color-warning, 5%);
    }

    span,
    .ms-Icon,
    .ms-Button-label {
      color: #ffffff;
    }
  }

  .ms-Button-flexContainer {
    transition: all ease-in-out 0.23s;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      box-sizing: border-box;
      width: 14px;
      height: 14px;
      margin-top: -7px;
      border: 2px solid #888;
      border-left-color: transparent;
      border-radius: 50%;
      background: transparent;
      opacity: 0;
      transition: all ease-in-out 0.23s;
    }
  }

  &.state--loading {
    .ms-Button-flexContainer {
      position: initial !important;
      margin-left: -14px;
      padding-right: 14px;

      &:after {
        right: 8px;
        opacity: 1;
        animation: rotateOpacity 0.95s ease-in-out infinite 0.1s;
      }
    }
  }

  &.primary {
    .ms-Button-flexContainer {
      &:after {
        border-color: #fff;
        border-left-color: transparent;
      }
    }
  }

  &.primary.state--loading .ms-Button-flexContainer:after,
  &.danger.state--loading .ms-Button-flexContainer:after,
  &.success.state--loading .ms-Button-flexContainer:after,
  &.warning.state--loading .ms-Button-flexContainer:after {
    border-color: #ffffff;
    border-left-color: transparent;
  }
}

.cr-icon {
  &.--green {
    color: $color-green-600;
  }
}

.ms-ContextualMenu-container {
  .ms-ContextualMenu-item {
    .cr-contextual-menu-item-icon {
      &.--icon-ChevronRight {
        margin: 0 0.2rem 0 0.9rem;
        font-weight: bold;
        font-size: 9px;
      }
    }
  }
}

.cr-icon-button {
  .ms-Button-flexContainer {
    position: initial !important;
  }

  .ms-TooltipHost {
    display: inline-block;
  }

  button {
    color: $color-primary;
    transition: all ease-in-out 0.15s;

    &.is-disabled {
      color: lighten(#a19f9d, 15%);
    }

    &:hover {
      color: darken($color-primary, 15%);
    }
  }

  .root-149:active > * {
    position: initial !important;
  }

  .root-175:active > * {
    position: initial !important;
  }

  .count-badge {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 15px;
    height: 15px;
    padding: 1px 0;
    border-radius: 50%;
    background-color: $color-primary;
    color: white;
    font-size: 10px;

    &:hover {
      background-color: darken($color-primary, 15%);
      cursor: pointer;
    }

    &.--primary {
      background-color: $color-primary;
    }
    &.--warning {
      background-color: $app-color-warning;
    }
    &.--danger {
      background-color: $app-color-danger;
    }
    &.--success {
      background-color: $app-color-success;
    }
  }

  .notice-badge {
    position: absolute;
    top: 1px;
    right: 3px;
    width: 8px;
    height: 8px;
    padding: 1px 0;
    border-radius: 50%;
    background-color: #999;
  }

  &.primary {
    button {
      background-color: $color-primary;
      color: #fff;

      &:hover {
        background-color: darken($color-primary, 3%);
      }

      &:active {
        background-color: darken($color-primary, 5%);
      }

      &.is-disabled {
        background-color: lighten($color-primary, 35%);

        .ms-Icon {
          color: #fff;
        }
      }
    }
  }

  &.warning {
    button {
      background-color: $app-color-warning;
      color: #fff;

      &:hover {
        background-color: darken($app-color-warning, 3%);
      }

      &:active {
        background-color: darken($app-color-warning, 5%);
      }

      &.is-disabled {
        background-color: lighten($app-color-warning, 35%);

        .ms-Icon {
          color: #fff;
        }
      }
    }
  }

  &.bordered {
    box-sizing: border-box;
    border: 1px solid #ccc;
  }

  &.inner-bordered {
    box-sizing: border-box;
    margin-left: 4px;
    box-shadow: 0 0 0 1px #ccc inset;

    .ms-TooltipHost {
      .ms-Button {
        &:hover {
          border: 1px solid #ccc;
        }
      }
    }
  }

  &.edit-icon-div {
    display: inline;
    .edit-icon {
      float: right;
    }
  }
}

.delete-btn {
  position: absolute;
  top: 4px;
  right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: none;

  .ms-Icon {
    margin-right: 0 !important;
    font-size: 8px !important;
  }
}

.left-bordered {
  border-left: 1px solid #ccc;
}

.append-button {
  margin-top: 4px;
  margin-right: 4px;
  border: 1px solid $color-primary !important;
  color: $color-primary;
  text-align: center;

  :hover {
    color: $color-primary;
  }
}

.append-button:last-child {
  margin-right: 0px;
  border: 1px solid $color-red-500 !important;
  color: $color-red-500 !important;

  .ms-Icon {
    color: $color-red-500;
  }

  :hover {
    color: $color-red-500 !important;
  }
}

.cr-buttons {
  display: flex;
  position: relative;

  .cr-buttons-collapse {
    display: none;

    .cr-icon-button {
      .ms-Button {
        margin-left: 0;

        .ms-Icon {
          rotate: -180deg;
          transition: all ease-in-out 0.15s;
          font-size: $font-size-2xl;
        }
      }
    }
  }

  &:not(.state--collapse) {
    .cr-buttons-collapse {
      .ms-Button {
        .ms-Icon {
          rotate: 0deg;
        }
      }
    }

    .cr-buttons-buttons {
      background: $app-content-background;
      box-shadow: 3px 0 2px #fff;
    }
  }

  .cr-buttons-buttons {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    transition: all ease-in-out 0.15s;
    max-width: calc(100vw - 63px);
  }

  @media (max-width: $break-xl) {
    .cr-buttons-collapse {
      display: block;
    }

    .cr-buttons-buttons {
      position: absolute;
      top: 0;
      left: 32px;
      opacity: 1;
    }

    &.state--collapse {
      .cr-buttons-buttons {
        max-width: 0;
        opacity: 0;
      }
    }
  }
}
