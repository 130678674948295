@media screen and (max-width: 1400px) {
}

$break-xs: 320px; /* iPhone 5 */
$break-sm: 375px; /* iPHone 6 */
$break-md: 414px; /* iPhone + */
$break-lg: 600px; /* iPhone ++ */
$break-xl: 768px;
$break-2xl: 1024px;
$break-3xl: 1200px;

@mixin forPager($attr, $md, $sm: false, $xs: false, $nano: false) {
  @if $md {
    @media screen and (max-width: $break-md) {
      #{$attr}: $md;
    }
  }

  @if $sm {
    @media screen and (max-width: $break-sm) {
      #{$attr}: $sm;
    }
  }

  @if $xs {
    @media screen and (max-width: $break-xs) {
      #{$attr}: $xs;
    }
  }

  @if $nano {
    @media screen and (max-width: calc(#{$break-xs} - 1px)) {
      #{$attr}: $nano;
    }
  }
}

@mixin forMobile($attr, $lg, $md, $sm: false, $xs: false, $nano: false) {
  @if $lg {
    @media screen and (max-width: $break-lg) {
      #{$attr}: $lg;
    }
  }

  @include forPager($attr, $md, $sm, $xs, $nano);
}

@mixin forTablet($attr, $xl, $lg: false, $md: false, $sm: false, $xs: false, $nano: false) {
  @if $xl {
    @media screen and (max-width: $break-xl) {
      #{$attr}: $xl;
    }
  }

  @include forMobile($attr, $lg, $md, $sm, $xs, $nano);
}

@mixin forLaptop($attr, $xl2, $xl: false, $lg: false, $md: false, $sm: false, $xs: false, $nano: false) {
  @if $xl2 {
    @media screen and (max-width: $break-2xl) {
      #{$attr}: $xl2;
    }
  }

  @include forTablet($attr, $xl, $lg, $md, $sm, $xs, $nano);
}

@mixin forDesktop($attr, $xl3, $xl2: false, $xl: false, $lg: false, $md: false, $sm: false, $xs: false, $nano: false) {
  @if $xl3 {
    @media screen and (max-width: $break-3xl) {
      #{$attr}: $xl3;
    }
  }

  @include forTablet($attr, $xl2, $xl, $lg, $md, $sm, $xs, $nano);
}
