@import "variables.scss";

$-app-sidebar-icon-visib-effect-size: 0.5rem;
$-app-sidebar-navi-padding-left: 1rem;
$-app-sidebar-navi-icon-width: 2.8rem;
$-app-sidebar-navi-nav-height: 2.57rem;

.app-hybrid-wrapper {
  .app-sidebar-ribbon {
    @keyframes fadeOut {
      0% {
        opacity: 1;
      }
      90% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    animation: fadeOut 5s forwards;
  }
}

.app-sidebar-ribbon {
  $-size: 60px;

  position: fixed;
  top: calc(#{$-size} / -2);
  left: calc(#{$-size} / -2);
  z-index: 30;
  overflow: hidden;
  width: $-size;
  height: $-size;
  background: #ed214b;
  color: #fff;
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.45);
  font-weight: bold;
  font-size: 10px;
  font-family: verdana;
  line-height: calc(#{$-size} * 1.75);
  text-align: center;
  user-select: none;
  transform: rotate(-45deg);

  &.--demo {
    background: #e95f20;
  }

  &.--local {
    background: #063aa1;
  }

  &.--dev {
    background: darken(#24e362, 10%);
  }
}

.app-wrapper {
  .app-sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    overflow: hidden;
    width: $app-sidebar-width;
    max-height: 100%;
    background: $app-content-box-background;
    transition: all ease-in-out $app-sidebar-transition-duration;

    &.state--no-navis {
      pointer-events: none;
    }

    .app-sidebar-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      .app-sidebar-logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: $app-header-height;
        background: $app-sidebar-background;
        color: $app-sidebar-logo-color;
        line-height: $app-header-height;
        text-align: left;
        user-select: none;

        > .logo {
          position: absolute;
          top: 0;
          left: $-app-sidebar-navi-padding-left;
          color: $app-sidebar-logo-color;

          font-weight: $font-weight-bold;
          font-size: $font-size-xl;
          text-decoration: none;
          transition: all ease-in-out $app-sidebar-transition-duration;

          > i {
            position: absolute;
            top: 0;
            left: 0;
            width: $-app-sidebar-navi-icon-width;
            height: $app-header-height;
            background: url("../image/logo_maven_icon_blue.png") center center / auto 20px no-repeat;
            font-size: $font-size-5xl;
            line-height: $app-header-height;
            text-align: center;
            text-indent: -9999px;
          }

          > span {
            $-logo-height: 22px;
            display: inline-block;
            width: 95px;
            height: $-logo-height;
            margin-left: $-app-sidebar-navi-icon-width;
            background: url("../image/logo_maven_text_blue.png") center left / 100% auto no-repeat;
            line-height: $-logo-height;
            text-indent: -9999px;
            transition: opacity ease-in-out $app-sidebar-transition-duration;
          }
        }

        > i {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 0;
          width: $app-sidebar-width-collapsed;
          height: $app-header-height;
          font-style: normal;

          &:before,
          &:after {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: $app-sidebar-width-collapsed;
            height: $app-header-height;
            font-size: $font-size-xl;
            font-family: "Material Icons Outlined";
            line-height: $app-header-height;
            text-align: center;
            cursor: pointer;
            transition: all ease-in-out $app-sidebar-transition-duration;
          }

          &.pin:before {
            content: "push_pin";
            left: $-app-sidebar-icon-visib-effect-size;
            opacity: 0;
          }

          &.pin:after {
            content: "album";
          }

          &.close:before {
            content: "clear";
            opacity: 0;
          }

          &.pin {
            z-index: 1;
          }
        }
      }

      .app-sidebar-nav {
        position: absolute;
        top: $app-header-height;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        color: $app-sidebar-color;

        ul,
        li {
          margin: 0;
          padding: 0;
          list-style: none;
        }

        .navi {
          padding: 0.5rem $-app-sidebar-navi-padding-left;
          background: $app-content-box-background;
          transition: padding-left ease-in-out $app-sidebar-transition-duration;

          &.secondary {
            //background: $app-content-background;
          }

          .divider {
            $-height: 1px;

            display: block;
            max-width: $app-sidebar-width;
            height: $-height;
            margin: 0.8rem calc(#{$app-content-box-padding} / 1.5);
            background: lighten($app-sidebar-color, 62%);
            transition: all ease-in-out $app-sidebar-transition-duration;
          }

          .head,
          .nav {
            position: relative;
            overflow: hidden;
            user-select: none;

            .btn {
              display: block;
              overflow: hidden;
              width: 100%;
              max-width: 100%;
              height: $-app-sidebar-navi-nav-height;
              padding: 0;
              border: 0;
              background: transparent;
              color: $app-sidebar-color;
              font-size: $font-size-lg;
              line-height: $-app-sidebar-navi-nav-height;
              text-align: left;
              text-decoration: none;
              cursor: pointer;
              transition: max-width ease-in-out $app-sidebar-transition-duration;

              .ico {
                position: absolute;
                top: 0;
                left: 0;
                width: $-app-sidebar-navi-icon-width;
                height: $-app-sidebar-navi-nav-height;
                font-size: $font-size-2xl;
                line-height: $-app-sidebar-navi-nav-height;
                text-align: center;
              }

              .maven-logo-icon {
                background: url("../image/logo_maven_icon_black.png") center center / auto 15px no-repeat;
              }

              .maven-docs-nav-icon {
                background: url("../image/maven_docs_nav_icon.png") center center / auto 18px no-repeat;
              }

              .maven-safety-nav-icon {
                background: url("../image/maven_safety_nav_icon.png") center center / auto 18px no-repeat;
              }

              .maven-tmf-nav-icon {
                background: url("../image/maven_tmf_nav_icon.png") center center / auto 18px no-repeat;
              }

              .maven-vdr-nav-icon {
                background: url("../image/maven_vdr_nav_icon.png") center center / auto 18px no-repeat;
              }

              .maven-tmf-study-icon {
                background: url("../image/maven_tmf_study_icon_gray.svg") center center / auto 18px no-repeat;
              }

              .maven-tmf-home-icon {
                background: url("../image/maven_tmf_home_icon_gray.svg") center center / auto 18px no-repeat;
              }

              .maven-tmf-archiver-icon {
                background: url("../image/maven_tmf_archiver_icon_gray.svg") center center / auto 18px no-repeat;
              }

              .maven-tmf-admin-icon {
                background: url("../image/maven_tmf_admin_icon_gray.svg") center center / auto 18px no-repeat;
              }

              span {
                margin-left: $-app-sidebar-navi-icon-width;
                transition: opacity ease-in-out $app-sidebar-transition-duration;
              }

              &:hover {
                color: darken($app-sidebar-color, 100%);
                text-decoration: none;
              }

              &:active,
              &:focus {
                outline: none;
              }
            }

            &.highlight {
              .btn {
                border-radius: 0.3rem;
                background: lighten($app-color-active, 15%);
                color: $app-sidebar-color-highlight;

                .maven-logo-icon {
                  background: url("../image/logo_maven_icon_white.png") center center / auto 15px no-repeat;
                }

                .maven-tmf-study-icon {
                  background: url("../image/maven_tmf_study_icon_white.svg") center center / auto 15px no-repeat;
                }

                .maven-tmf-home-icon {
                  background: url("../image/maven_tmf_home_icon_white.svg") center center / auto 15px no-repeat;
                }

                .maven-tmf-archiver-icon {
                  background: url("../image/maven_tmf_archiver_icon_white.svg") center center / auto 15px no-repeat;
                }

                .maven-tmf-admin-icon {
                  background: url("../image/maven_tmf_admin_icon_white.svg") center center / auto 15px no-repeat;
                }
              }
            }
          }

          .group {
            .head {
              &:before {
                content: "keyboard_arrow_right";
                position: absolute;
                top: 0;
                right: 0.3rem;
                padding: 0;
                border: none;
                font-size: $font-size-xl;
                font-family: "Material Icons";
                line-height: $-app-sidebar-navi-nav-height;
                transition: all ease-in-out 0.15s;
              }
            }

            .nav {
              max-height: 0;
              transition: max-height ease-in-out 0.2s;

              .ico {
                font-size: calc(#{$font-size-xs} * 0.7) !important;
              }
            }

            &.highlight {
              .head {
                .btn {
                  background: fade-out($app-sidebar-group-background-highlight, 0.4);
                  color: darken($app-sidebar-color, 15%);
                }

                &:before {
                  transform: rotate(90deg);
                }
              }

              .nav {
                max-height: $-app-sidebar-navi-nav-height;
              }
            }
          }
        }

        .app-sidebar-version {
          margin-bottom: 16px;
          margin-left: 24px;
          color: grey;
          font-size: 12px;
          white-space: nowrap;
        }
      }

      .app-sidebar-nav::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.app-wrapper .app-layout.maven-clinical-cloud,
.app-wrapper .app-layout.maven-sso,
.app-wrapper .app-layout.maven-portal,
.app-wrapper .app-layout.maven-auth,
.app-wrapper .app-layout.maven-ocr,
.app-wrapper .app-layout.maven-admin {
  .app-header .logo {
    width: 140px;
    background-image: url("../image/logo_maven_clinical_cloud_text_white.png");
  }
  .app-sidebar-wrapper .app-sidebar-logo > .logo > span {
    width: 140px;
    background-image: url("../image/logo_maven_clinical_cloud_text_blue.png");
  }
}

@media (max-width: 540px) {
  .app-wrapper .app-layout.maven-clinical-cloud,
  .app-wrapper .app-layout.maven-auth,
  .app-wrapper .app-layout.maven-admin {
    .app-header .logo {
      width: 120px;
    }
    .app-sidebar-wrapper .app-sidebar-logo > .logo > span {
      width: 120px;
    }
  }
}

.app-wrapper .app-layout.maven-builder {
  .app-header .logo {
    width: 107px;
    background-image: url("../image/logo_maven_builder_text_white.png");
  }
  .app-sidebar-wrapper .app-sidebar-logo > .logo > span {
    width: 107px;
    background-image: url("../image/logo_maven_builder_text_blue.png");
  }
}

.app-wrapper .app-layout.maven-safety {
  .app-header .logo {
    width: 100px;
    background-image: url("../image/logo_maven_safety_text_white.png");
  }
  .app-sidebar-wrapper .app-sidebar-logo > .logo > span {
    width: 107px;
    background-image: url("../image/logo_maven_safety_text_blue.png");
  }
}

.app-wrapper .app-layout.maven-validator {
  .app-header .logo {
    width: 120px;
    background-image: url("../image/logo_maven_validator_text_white.png");
  }
  .app-sidebar-wrapper .app-sidebar-logo > .logo > span {
    width: 107px;
    background-image: url("../image/logo_maven_validator_text_blue.png");
  }
}

.app-wrapper .app-layout.maven-docs {
  .app-header .logo {
    width: 90px;
    background-image: url("../image/logo_maven_docs_text_white.png");
  }
  .app-sidebar-wrapper .app-sidebar-logo > .logo > span {
    width: 90px;
    background-image: url("../image/logo_maven_docs_text_blue.png");
  }
}

.app-wrapper .app-layout.maven-tmf {
  $header-height: 56px;
  $footer-height: 66px;
  .app-header .logo {
    width: 90px;
    background-image: url("../image/logo_maven_tmf_text_white.png");
  }
  .app-sidebar-wrapper .app-sidebar-logo > .logo > span {
    width: 90px;
    background-image: url("../image/logo_maven_tmf_text_blue.png");
  }
  .app-header {
    .app-header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 450px;
      height: $header-height;
      background-color: #2eabd8;

      &::after {
        display: none;
      }
    }
  }

  .app-content {
    padding: 0;
    background-color: #f4f6f9;
  }

  .app-footer {
    display: none; // NOTE: Hide Footer. tmf에서 따로 가지게 될 것이기 때문에 일단 숨김
    background-color: #f4f6f9;
  }
}

@mixin --sb-collapse-sidebar {
  width: $app-sidebar-width-collapsed;

  .app-sidebar-logo .logo {
    > span {
      opacity: 0;
    }
  }

  .divider {
    $-padding: 0.95rem;

    max-width: calc(#{$-app-sidebar-navi-icon-width} - (#{$-padding} * 2)) !important;
    margin-left: $-padding !important;
  }

  .navi {
    padding-left: calc((#{$app-sidebar-width-collapsed - $-app-sidebar-navi-icon-width}) / 2);

    .btn {
      max-width: $-app-sidebar-navi-icon-width !important;

      span {
        opacity: 0;
      }
    }

    .head {
      &:before {
        opacity: 0;
      }
    }
  }
}

@mixin --sb-collapse-main {
  .app-header {
    left: $app-sidebar-width-collapsed;
  }

  .app-main {
    margin-left: $app-sidebar-width-collapsed;

    .cr-footer-bar {
      left: $app-sidebar-width-collapsed;
    }
  }
}

@mixin --sb-hamburger-main {
  .app-header {
    left: 0 !important;
  }

  .app-main {
    margin-left: 0 !important;

    .cr-footer-bar {
      left: 0 !important;
    }
  }
}

@mixin --sb-collapse-sidebar-icon {
}

.app-wrapper {
  .app-layout {
    @media (max-width: $app-sidebar-break-side) {
      .app-sidebar-logo > i {
        opacity: 0;
      }
    }

    // When Collapsed
    &.sb-collapsed {
      @include --sb-collapse-main;

      // When Not Hover
      .app-sidebar:not(:hover) {
        @include --sb-collapse-sidebar;

        .app-sidebar-logo > i {
          opacity: 0;
        }

        .app-sidebar-version {
          display: none;
        }
      }

      &.sb-collapsed-force {
        .app-sidebar {
          @include --sb-collapse-sidebar;

          .app-sidebar-logo > i {
            opacity: 0;
          }
        }
      }

      .app-sidebar-logo i.pin:before {
        left: 0 !important;
        opacity: 1 !important;
      }
      .app-sidebar-logo i.pin:after {
        left: -$-app-sidebar-icon-visib-effect-size;
        opacity: 0;
      }
    }

    // Not Collapsed & Auto Collapse Width
    &:not(.sb-collapsed) {
      @media (max-width: $app-sidebar-break-side) {
        @include --sb-collapse-main;

        .app-sidebar:not(:hover) {
          @include --sb-collapse-sidebar;
        }
      }
    }
  }
}

.app-wrapper {
  .app-layout {
    &.sb-collapsed {
      .app-header {
        .logo {
          @media (min-width: $break-lg) {
            margin-left: calc(#{$app-content-padding} + 8px);
            opacity: 1;
          }
        }
      }
    }

    &:not(.sb-collapsed) {
      @media (max-width: $app-sidebar-break-side) {
        .app-header {
          .logo {
            @media (min-width: $break-lg) {
              margin-left: calc(#{$app-content-padding} + 8px);
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.app-wrapper {
  .app-layout {
    @media (max-width: $app-sidebar-break-icon) {
      @include --sb-hamburger-main;
      .app-sidebar {
        width: 0 !important;
        max-width: 100%;
        margin-left: -1rem;
        visibility: hidden;
      }
    }
  }
}

.app-wrapper {
  .app-layout {
    @media (max-width: $app-sidebar-break-icon) {
      &.sb-opened {
        .app-sidebar {
          left: 0 !important;
          width: 100% !important;
          margin-left: 0 !important;
          opacity: 1;
          visibility: visible;

          .app-sidebar-logo {
            .logo > span {
              opacity: 1 !important;
            }
          }

          .navi {
            .divider {
              max-width: 100% !important;
            }

            .head,
            .nav {
              &:before {
                opacity: 1 !important;
              }

              .btn {
                max-width: 100% !important;

                span {
                  opacity: 1 !important;
                }
              }
            }
          }

          i.pin {
            z-index: 0;
            opacity: 0;
          }

          i.close {
            z-index: 1;
            opacity: 1 !important;

            &:before {
              opacity: 1;
            }
          }
        }
      }
    }

    .app-header {
      @media (max-width: $app-sidebar-break-icon) {
        .hamburger {
          opacity: 1 !important;
        }

        .logo {
          opacity: 1 !important;
        }
      }

      @media (max-width: 550px) {
        .logo {
          opacity: 0 !important;
        }
      }

      .logo {
        $-logo-height: 22px;

        display: inline-block;
        width: 95px;
        height: $-logo-height;

        margin-left: calc(#{$app-header-height} - 0.3rem);
        background: url("../image/logo_maven_text_white.png") center left / 100% auto no-repeat;
        color: $app-header-color-highlight;
        line-height: $-logo-height;
        text-decoration: none;
        text-indent: -9999px;
        opacity: 0;
        transition: all ease-in-out 0.2s;
      }

      .hamburger {
        position: absolute;
        top: 0;
        left: 0;
        width: $app-header-height;
        height: $app-header-height;
        border: 0;
        background: transparent;
        color: $app-header-color-highlight;
        outline: none;
        font-size: $font-size-4xl;
        line-height: calc(#{$app-header-height} * 0 / 9);
        opacity: 0;
        cursor: pointer;
        transition: all ease-in-out 0.2s;
      }
    }
  }
}

.sidebar--header--sib {
  .app-wrapper {
    .app-sidebar {
      z-index: auto;
    }
  }
}
