.al-button {
  @include rippleEffect($app-content-box-background, 0, 5%, true);
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  max-width: 100%;

  padding: calc(#{$app-content-box-padding} / 3) $app-content-box-padding;
  border-width: 1px;
  border-style: solid;
  background-position: center;
  outline: none !important;
  font-size: $font-size-base;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;

  &.primary {
    border-color: $app-highlight-color;
    color: $app-highlight-color;
  }

  &.warning {
    border-color: $app-color-warning;
    color: $app-color-warning;
  }

  &.danger {
    border-color: $app-color-danger;
    color: $app-color-danger;
  }

  &.success {
    border-color: $app-color-success;
    color: $app-color-success;
  }

  &.black {
    border-color: $app-color-black;
    color: $app-color-black;
  }

  &.filled {
    color: $app-highlight-text-color;

    &.primary {
      @include rippleEffect($app-highlight-color);
    }

    &.warning {
      @include rippleEffect($app-color-warning);
    }

    &.danger {
      @include rippleEffect($app-color-danger);
    }

    &.success {
      @include rippleEffect($app-color-success);
    }

    &.black {
      @include rippleEffect($app-color-black);
    }
  }

  &.sm {
    padding: calc(#{$app-content-box-padding} / 3.5) calc(#{$app-content-box-padding} / 1.5);
    font-size: $font-size-sm;
  }

  &.xs {
    padding: calc(#{$app-content-box-padding} / 4) calc(#{$app-content-box-padding} / 2);
    font-size: $font-size-xs;
  }
}

.al-buttons {
  .al-button {
    margin-left: 0.2rem;

    &:first-child {
      margin-left: 0;
    }
  }
}
