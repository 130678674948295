.cr-tabs {
  position: relative;
  overflow: hidden;
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 120%;
  }

  .cr-tabs-wrapper {
    @include forTablet(margin-bottom, 1rem);
    @include transition(0.2s);

    position: relative;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 1.5rem;
    padding-bottom: 3px;
    background: $app-content-background;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .cr-tab {
    @keyframes bounce {
      0%,
      20%,
      50%,
      80%,
      100% {
        transform: scaleX(1);
      }
      40% {
        transform: scaleX(1.1);
      }
      60% {
        transform: scaleX(0.9);
      }
    }

    position: relative;
    font-size: $font-size-base;
    white-space: nowrap;

    &:after {
      @include transition(0.2s);
      content: "";
      position: absolute;
      bottom: -3px;
      left: 50%;
      width: 0;
      height: 3px;
      margin-left: 0;
      opacity: 0;
    }

    .link {
      @include transition(0.2s);
      @include forTablet("padding", 0.8rem 2.5rem, 0.8rem 2rem, 0.8rem 1.5rem);

      @include rippleEffect($app-content-background, 0%, 4%, true);
      padding: 0.8rem 3rem;
    }

    a {
      display: inline-block;
      color: $app-text-color;
      text-decoration: none;
      cursor: pointer;
    }

    &:hover,
    &:hover a {
      color: $app-highlight-color;
    }

    &.highlight {
      .link {
        color: $app-highlight-color;
      }

      &:after {
        left: 50%;
        width: 100%;
        margin-left: -50%;
        background-color: $app-highlight-color;
        opacity: 1;
        animation-name: bounce;
        animation-duration: 0.4s;
        animation-timing-function: ease-in;
        animation-fill-mode: forwards;
      }
    }
  }
}
