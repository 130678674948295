.al-icon {
  $-font: $font-size-2xl;
  $-size: 2rem;

  display: inline-block;
  width: $-size;
  height: $-size;
  line-height: $-size;
  text-align: center;

  > .icon {
    margin-left: 0.1rem;
    font-weight: $font-weight-normal;
    font-style: normal;
    font-size: $-font;
    font-family: "Material Icons Outlined";
  }

  &.xs {
    width: calc(#{$-size} * 0.6);
    height: calc(#{$-size} * 0.6);
    line-height: calc(#{$-size} * 0.6);

    > .icon {
      margin-left: 0.1rem;
      font-size: calc(#{$-font} * 0.6);
    }
  }

  &.sm {
    width: calc(#{$-size} * 0.8);
    height: calc(#{$-size} * 0.8);
    line-height: calc(#{$-size} * 0.85);

    > .icon {
      margin-left: 0.05rem;
      font-size: calc(#{$-font} * 0.8);
    }
  }

  &.lg {
    width: calc(#{$-size} * 1.4);
    height: calc(#{$-size} * 1.4);
    line-height: calc(#{$-size} * 1.4);

    > .icon {
      margin-left: 0;
      font-size: calc(#{$-font} * 1.4);
    }
  }

  &.xl {
    width: calc(#{$-size} * 1.7);
    height: calc(#{$-size} * 1.7);
    line-height: calc(#{$-size} * 1.75);

    > .icon {
      margin-left: 0;
      font-size: calc(#{$-font} * 2);
    }
  }

  &.\32xl {
    width: calc(#{$-size} * 2);
    height: calc(#{$-size} * 2);
    line-height: calc(#{$-size} * 2.05);

    > .icon {
      margin-left: 0;
      font-size: calc(#{$-font} * 2.3);
    }
  }

  &.rounded {
    border-radius: 50% !important;
    background: $app-content-background;
  }
}
