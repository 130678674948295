.cr-contextual-menu {
  position: relative;
  
  .ms-Button-menuIcon {
    transition: all ease-in-out 0.23s;
  }

  span > i.ms-Button-menuIcon {
    margin-top: 0.1rem;
    margin-left: 0.8rem;
    transition: all ease-in-out 0.15s;
  }

  &.is-expanded {
    span > i.ms-Button-menuIcon {
      transform: rotate(180deg);
    }
  }

  .ms-ContextualMenu-itemText {
    color: $color-gray-800;
  }

  &.style--bordered {
    border-color: #ccc;
    border-radius: 0;
    outline: none;
    background: #fff;
    height: 32px;

    .ms-Button-textContainer {
      padding-left: 8px;
    }

    &:hover {
      border-color: #aaa;
    }

    &:active {
      border-color: #bbb;
    }
  }

  &.color--primary {
    border-color: $color-primary;
    background-color: $color-primary;

    &:hover {
      border-color: darken($color-primary, 3%);
      background-color: darken($color-primary, 3%);
    }

    &:active {
      border-color: darken($color-primary, 5%);
      background-color: darken($color-primary, 5%);
    }

    span,
    .ms-Icon,
    .ms-Button-label {
      color: #ffffff;
    }
  }

  &.color--danger {
    border-color: $app-color-danger;
    background-color: $app-color-danger;

    &:hover {
      border-color: darken($app-color-danger, 3%);
      background-color: darken($app-color-danger, 3%);
    }

    &:active {
      border-color: darken($app-color-danger, 5%);
      background-color: darken($app-color-danger, 5%);
    }

    span,
    .ms-Icon,
    .ms-Button-label {
      color: #ffffff;
    }
  }

  &.color--success {
    border-color: $app-color-success;
    background-color: $app-color-success;

    &:hover {
      border-color: darken($app-color-success, 3%);
      background-color: darken($app-color-success, 3%);
    }

    &:active {
      border-color: darken($app-color-success, 5%);
      background-color: darken($app-color-success, 5%);
    }

    span,
    .ms-Icon,
    .ms-Button-label {
      color: #ffffff;
    }
  }

  &.color--warning {
    border-color: $app-color-warning;
    background-color: $app-color-warning;

    &:hover {
      border-color: darken($app-color-warning, 3%);
      background-color: darken($app-color-warning, 3%);
    }

    &:active {
      border-color: darken($app-color-warning, 5%);
      background-color: darken($app-color-warning, 5%);
    }

    span,
    .ms-Icon,
    .ms-Button-label {
      color: #ffffff;
    }
  }

  &.is-disabled {
    opacity: 0.65;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 8px;
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    margin-top: -7px;
    border: 2px solid #fff;
    border-left-color: transparent;
    border-radius: 50%;
    background: transparent;
    opacity: 0;
    transition: all ease-in-out 0.23s;
  }

  &.state--loading {
    cursor: not-allowed;

    .ms-Button-menuIcon {
      opacity: 0;
    }

    &:after {
      opacity: 1.0;
      animation: rotateOpacity 0.95s ease-in-out infinite 0.1s;
    }
  }
}

.cr-contextual-menu-item-icon {
  margin: 0 0.4rem 0 0.7rem;
  color: $color-gray-800;
}

.ms-Callout-container {
  z-index: 1;
}

.cr-contextual-menu.cr-icon-button {
  .ms-ContextualMenu-itemText,
  .cr-contextual-menu-item-icon { 
    color: $color-primary;

    &.tiny {
      margin: 0 0 0 0.3rem;
      font-size: 12px;
    }
  }

  .ms-ContextualMenu-link.is-disabled {
    .ms-ContextualMenu-itemText,
    .cr-contextual-menu-item-icon { 
      color: lighten(#a19f9d, 15%);
    }
  }
}

.ms-ContextualMenu-container {
  .ms-ContextualMenu {
    .ms-ContextualMenu-item {
      .ms-ContextualMenu-linkContent {
        .ms-ContextualMenu-itemText {
          padding-left: 5px;
        }

        i ~ .ms-ContextualMenu-itemText {
          padding-left: 0;
        }
      }

      &.state--disabled {
        i, span {
          color: lighten($app-text-color, 40%);
        }

        &:hover button {
          background: transparent !important;
          cursor: default !important;
        }
      }
    }
  }
}