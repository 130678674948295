.cr-image-view {
  .ms-Image {
    .ms-Image-image {
      display: block;
      margin: 0 auto;
    }
  }

  .cr-image-index {
    margin-top: 1rem;
    font-size: 13px;
    text-align: center;
  }
  .cr-image-control {
    display: flex;
    justify-content: space-around;

    .cr-image {
      width: calc(100% - 160px);
      max-width: 800px;
      border: 1px solid #cccccc;
    }

    .cr-image-control-left {
      margin: auto;
      .cr-image-control-left-button {
        border: none;
        color: $color-primary;
      }
      .cr-image-control-left-button .ms-Button-icon {
        font-size: 2rem;
      }
    }
    .cr-image-control-right {
      margin: auto;
      .cr-image-control-right-button {
        border: none;
        color: $color-primary; 
      }
      .cr-image-control-right-button .ms-Button-icon {
        font-size: 2rem;
      }
    }
  }
}
