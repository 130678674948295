$font-size-xs: 0.69rem;
$font-size-sm: 0.75rem;
$font-size-base: 0.82rem;
$font-size-lg: 0.91rem;
$font-size-xl: 1rem;
$font-size-2xl: 1.125rem;
$font-size-3xl: 1.25rem;
$font-size-4xl: 1.5rem;
$font-size-5xl: 1.875rem;
$font-size-6xl: 2.25rem;
$font-size-7xl: 2.7rem;
$font-size-8xl: 3.125rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
