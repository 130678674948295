@import "variables.scss";

.app-auth {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 100%;

  .app-auth-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: #ffffff;

    .app-auth-side {
      float: left;
      width: 800px;
      min-height: 100%;
      background: linear-gradient(45deg, $color-primary, darken($color-primary, 35%));
      transition: all ease-in-out 0.2s;

      @media (max-width: 1700px) {
        width: 45%;
      }

      @media (max-width: 1400px) {
        width: 40%;
      }

      @media (max-width: 600px) {
        width: 20%;
      }

      @media (max-width: 480px) {
        width: 10px;
      }
    }

    .app-auth-content {
      float: right;
      width: calc(100% - 800px);
      min-height: 100%;
      background: #fff;
      transition: all ease-in-out 0.2s;

      @media (max-width: 1700px) {
        width: 55%;
      }

      @media (max-width: 1400px) {
        width: 60%;
      }

      @media (max-width: 600px) {
        width: 80%;
      }

      @media (max-width: 480px) {
        width: calc(100% - 10px);
      }
    }

    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .app-auth-side-wrapper {
    &:before {
      content: "";
      position: absolute;
      bottom: -10vw;
      left: -5vh;
      width: 25vw;
      height: 25vw;
      border-radius: 50%;
      background: #ffffff;
      opacity: 0.03;
    }

    &:after {
      content: "";
      position: absolute;
      top: 25vh;
      right: -10vw;
      width: 30vw;
      height: 30vw;
      border-radius: 50%;
      background: #ffffff;
      opacity: 0.02;
    }
  }

  .app-auth-side {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: -15vh;
      left: -5vh;
      width: 20vw;
      height: 20vw;
      border-radius: 50%;
      background: #ffffff;
      opacity: 0.04;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(-45deg, fade-out($color-primary, 1) 0%, darken($color-primary, 10%) 100%);
      opacity: 0.5;
    }
  }

  .app-auth-side {
    position: relative;
    overflow: hidden;

    strong {
      display: none;
    }

    p {
      @keyframes left-to-right-1 {
        from {
          left: -5%;
          opacity: 0;
        }

        to {
          left: 105%;
          opacity: 1;
        }
      }

      @keyframes bottom-to-top-1 {
        from {
          bottom: -5%;
          opacity: 0;
        }

        to {
          bottom: 95%;
          opacity: 1;
        }
      }

      @keyframes bottom-to-top-2 {
        from {
          bottom: 0%;
          opacity: 0;
        }

        to {
          bottom: 25%;
          opacity: 1;
        }
      }

      @keyframes bottom-to-top-3 {
        from {
          bottom: 100%;
          opacity: 0;
        }

        to {
          bottom: 70%;
          opacity: 1;
        }
      }

      @keyframes bottom-to-top-4 {
        from {
          bottom: 50%;
          opacity: 0;
        }

        to {
          bottom: 0%;
          opacity: 1;
        }
      }

      span {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.3rem;
        opacity: 0.8;
        animation-name: bottom-to-top-1;
        animation-duration: 5s;
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
        animation-iteration-count: infinite;
        animation-fill-mode: both;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 0.2rem;
          height: 0.2rem;
          border-radius: 50%;
          background: #fff;
          opacity: 0.3;

          animation-name: left-to-right-1;
          animation-duration: 5s;
          animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
          animation-iteration-count: infinite;
          animation-fill-mode: both;
        }
      }

      span:nth-child(1) {
        animation-name: bottom-to-top-2;
        animation-delay: 3s;

        &:after {
          animation-delay: 3s;
        }
      }

      span:nth-child(2) {
        animation-name: bottom-to-top-3;
        animation-delay: 10s;

        &:after {
          animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
          animation-delay: 10s;
        }
      }

      span:nth-child(3) {
        animation-name: bottom-to-top-4;
        animation-delay: 15s;

        &:after {
          animation-delay: 15s;
        }
      }

      span:nth-child(4) {
        animation-name: bottom-to-top-1;
        animation-duration: 12s;
        animation-delay: 20s;

        &:after {
          animation-duration: 12s;
          animation-delay: 20s;
        }
      }

      span:nth-child(5) {
        animation-name: bottom-to-top-2;
        animation-duration: 10s;
        animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-delay: 20s;

        &:after {
          animation-duration: 10s;
          animation-delay: 20s;
        }
      }

      span:nth-child(6) {
        animation-name: bottom-to-top-3;
        animation-delay: 6s;

        &:after {
          animation-delay: 6s;
        }
      }

      span:nth-child(7) {
        animation-name: bottom-to-top-4;
        animation-delay: 5s;

        &:after {
          animation-delay: 5s;
        }
      }
    }
  }

  .app-auth-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    height: 100%;
  }

  .app-auth-content-wrapper {
    width: 500px;
    max-width: 100%;
    margin-bottom: 5rem;
    padding: 2rem;
    transition: all ease-in-out 0.2s;

    @media (max-height: 900px) {
      margin-bottom: 3rem;
    }

    @media (max-height: 600px) {
      margin-bottom: 0rem;
    }

    @media (max-width: 480px) {
      padding: 2rem 1.5rem;
    }

    .logo {
      $-logo-height: 15px;
      $-logo-size: 33px;

      position: relative;
      box-sizing: content-box;
      height: $-logo-height;
      margin: calc(#{$-logo-size} * 2) 0 2rem 0;
      background: url('../image/logo_maven_text_blue.png') bottom left / auto 100% no-repeat;
      line-height: $-logo-height;
      text-indent: -9999px;
      transition: all ease-in-out 0.2s;

      @media (max-width: 480px) {
        background-position: bottom center;
        
        &:after {
          left: 50% !important;
          transform: translateX(-50%);
        }
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -6px;
        left: 0;
        display: block;
        height: 3px;
        border-radius: 3px;
        background: transparent;
        transition: all ease-in-out 0.25s;
      }

      &.maven-clinical-cloud,
      &.maven-sso,
      &.maven-portal,
      &.maven-auth,
      &.maven-ocr,
      &.maven-admin {
        background-image: url('../image/logo_maven_clinical_cloud_text_blue.png');
      }

      &.maven-builder {
        background-image: url('../image/logo_maven_builder_text_blue.png');
        
        &:after {
          width: 132px;
          background: $color-orange-400;
        }
      }

      &.maven-safety {
        height: calc(#{$-logo-height} * 1.25);
        margin-bottom: calc(2rem - ((#{$-logo-height} * 1.25) - #{$-logo-height})) !important;
        background-image: url('../image/logo_maven_safety_text_blue.png');
        line-height: calc(#{$-logo-height} * 1.25);

        &:after {
          bottom: -2px;
          width: 123px;
          background: $color-yellow-500;
        }
      }

      &.maven-validator {
        background-image: url('../image/logo_maven_validator_text_blue.png');

        &:after {
          width: 148px;
          background: $color-orange-400;
        }
      }

      &.maven-docs {
        background-image: url('../image/logo_maven_docs_text_blue.png');

        &:after {
          width: 110px;
          background: $color-red-700;
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: calc((#{$-logo-size} * -1) - 13px);
        left: -2px;
        display: block;
        width: $-logo-size;
        height: $-logo-size;
        background: url('../image/logo_maven_icon_circle_blue.png') center center / 100% auto no-repeat;
        font-size: $font-size-5xl;
        transition: all ease-in-out 0.2s;

        @media (max-width: 480px) {
          left: 50%;
          margin-left: calc(#{$-logo-size} / -2);
        }
      }
    }

    .header {
      $-break-width: 480px;

      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      @media (max-width: $-break-width) {
        flex-wrap: wrap;
      }

      .title {
        flex-grow: 0;
        margin: 0;
        font-size: $font-size-4xl;
        text-transform: uppercase;
        transition: all ease-in-out 0.2s;

        @media (max-width: $-break-width) {
          width: 100%;
          text-align: center;
        }

        span {
          display: block;
          margin-top: 5px;
          font-weight: normal;
          font-size: $font-size-base;
          text-transform: none;
        }
      }

      .help {
        display: none;
        flex-grow: 1;
        margin: 0;
        text-align: right;

        @media (max-width: 480px) {
          margin-top: 0.5rem;
          text-align: left;
        }
      }
    }

    .form {
      > fieldset {
        margin: 0;
        padding: 0;
        border: none;

        > legend {
          display: none;
        }

        > label {
          position: relative;
          display: block;
          margin-bottom: 0.7rem;

          input {
            width: 100%;
            padding: 0.6rem 0.7rem;
            border: 1px solid $app-form-border-color;
            border-radius: 0.3rem;
            font-size: $font-size-lg;
            transition: all ease-in-out 0.25s;

            &::placeholder {
              padding-left: 0.2rem;
              color: darken($app-form-border-color, 55%);
            }

            &:hover {
              border: 1px solid darken($app-form-border-color, 15%);
            }

            &:focus {
              border: 1px solid $color-primary;
              box-shadow: none;
              outline: none;
            }

            &.state--error {
              border-color: $app-color-danger;
            }

            &.email,
            &.password {
              &:before {
                content: "xx";
                top: 0;
                left: 0;
                display: block;
              }
            }
          }

          &.type--check {
            display: flex;
            text-transform: unset !important;
            cursor: pointer;

            p {
              margin: -1px 0 0 5px;
              font-weight: normal;
              font-size: $font-size-base;
              line-height: 155%;

              strong {
                color: lighten($app-text-color, 40%);
                font-weight: normal;
              }
            }
          }

          &.legal--all {
            margin-top: 15px;

            p {
              font-weight: bold;
            }
          }

          &.legal--agree-age {
            margin-bottom: 15px;
          }
        }

        hr {
          width: 100%;
          height: 1px;
          margin: 0.7rem 0;
          padding: 0;
          background: #E8E8E8;
        }

        &.type--label {
          > label {
            color: lighten($app-form-color, 30%);
            font-weight: bold;
            font-size: $font-size-xs;
            text-transform: uppercase;
            
            input {
              margin-top: 5px;
            }

            em {
              display: block;
              margin-top: 5px;
              margin-bottom: 20px;
              margin-left: 5px;
              font-weight: normal;
              font-style: normal;
              font-size: $font-size-xs;
              text-transform: none;
            }
          }
        }
      }

      .button {
        hr {
          position: relative;
          width: 100%;
          height: 20px;
          margin: 30px 0 10px 0;
          padding: 0;
          background: transparent;

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 1%;
            width: 98%;
            height: 1px;
            background: #CCC;
          }

          &:after {
            content: 'or';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 20px;
            height: 21px;
            margin: -11px 0 0 -10px;
            border-radius: 5px;
            background: #FFFFFF;
            color: #000;
            line-height: 21px;
            text-align: center;
          }
        }

        button, .cr-button {
          position: relative;
          display: block;
          overflow: hidden;
          width: 100%;
          height: auto;
          padding: 0.85rem 0;
          border: none;
          border-radius: 0.3rem;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          transition: all ease-in-out 0.1s;

          &:not(.is-disabled) {
            cursor: pointer;
          }

          span {
            font-weight: normal;
          }

          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background: #fff;
            opacity: 0;
            transition: all ease-in-out 0.2s;
          }
        }

        hr + button,
        hr + .cr-button {
          box-sizing: border-box;
          background: transparent !important;
          color: $color-primary !important;
          font-weight: bold;
          text-transform: none;

          em {
            color: $app-text-color;
            font-weight: normal;
            font-style: normal;
          }

          &:hover {
            text-decoration: underline;
          }
        }

        p {
          text-align: center;

          > a {
          }
        }
      }
    }

    .copyright {
      bottom: 2rem;
      left: 0;
      //position: absolute;
      display: block;
      width: 100%;
      color: lighten(#000, 80%);
      font-weight: normal;
      font-size: $font-size-sm;
      text-align: center;

      a {
        color: lighten(#000, 50%);
      }

      .privacy-policy {
        padding-bottom: 1rem;
        font-size: $font-size-base;
      }
    }
  }
}

.maven-clinical-cloud--sso {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &.state--recursive {
    .app-spinner {
      position: relative;
      top: auto;
      left: auto;
      width: auto;
      height: auto;
      margin: 0;
      padding: 0;
      border: none;

      white-space: nowrap;
      &:before,
      &:after {
        display: none;
      }
    }
  }
}
