.hb-text {
  display: flex;
  flex-direction: row;
  border: 2px solid rgba(0, 0, 0, 0.03);

  &:focus-within {
    border: 2px solid $color-primary;
    outline: none;
  }

  .hb-text-affix {
    display: flex;
    align-items: center;
    background: #f0f0f0;
    word-break: keep-all;
    padding: 0px 10px;
  }

  input,
  textarea {
    width: 100%;
    padding: 0.2rem 0.4rem;
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
    font-size: 22px;
    appearance: none;
    border: none;

    &::placeholder {
      color: #bbb;
    }

    &:disabled {
      background: #f0f0f0;
    }
  }

  textarea {
    height: calc(40vh);
    min-height: 150px;
    max-height: 700px;
    resize: vertical;
  }
}
