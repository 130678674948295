@import "variables.scss";

.app-ecoa-page-home-code-verification {
  .app-ecoa-page-home-code-verification-ok-btn {
    button {
      width: 100%;
      max-width: calc((50px * 6) + (2px * 10));
    }
  }
}

.app-ecoa-page-home-identity-verification {
  .app-ecoa-page-home-identity-verification-ok-btn {
    button {
      width: 100%;
      max-width: calc((50px * 4) + (2px * 6));
    }
  }
}

.app-ecoa-page-home-recruit-paragraph {
  display: block;
  margin: 10px 0;
  line-height: 140%;
  word-break: keep-all;
  > dl {
    margin: 0;
    padding-bottom: 40px;
    font-size: 16px;
    line-height: 1.5;
    > dt {
      margin-bottom: 20px;
      font-weight: 700;
    }
    > dd {
      position: relative;
      margin: 0;
      white-space: pre-wrap;
    }
    > dd.--unordered {
      padding: 0 0 0 20px;
    }
    > dd.--unordered::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 5px;
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: $color-gray-800;
    }
  }
}

.app-ecoa-time-wrapper {
  margin-top: 0.5rem;

  .--item-time {
    display: flex;
    align-items: center;

    .--prefix {
      margin-right: 0.25rem;
      font-size: $font-size-base;
    }

    .--suffix {
      margin-left: 0.25rem;
      font-size: $font-size-base;
    }
    .--delimiter {
      font-size: $font-size-base;
    }

    .cr-dropdown {
      display: inline-block;
      width: calc(100% / 2 - 0.25rem);
      margin: 0 0.25rem;
    }

    .cr-dropdown:first-child {
      margin-left: 0;
    }
    .cr-dropdown:last-child {
      margin-right: 0;
    }
  }
}

.app-ecoa-children-time-wrapper {
  position: relative;
  top: -8px;
  width: 100%;
  padding: 0 10px 10px 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #fff;

  .--prefix {
    flex-shrink: 0;
  }

  .--suffix {
    flex-shrink: 0;
  }

  &.--disabled {
    background: #f0f0f0;
  }
}
