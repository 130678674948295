.cr-steps {
  display: flex;
  justify-content: center;

  .cr-step {
    $cr-step-size: 50px;
    $cr-step-spacing: 10px;
    $cr-step-bar-color: $color-gray-300;
    $cr-step-bar-color-highlighted: $color-primary;
    $cr-step-bar-height: 3px;
    $cr-step-padd: 10px;
    
    position: relative;
    display: flex;
    flex-basis: 100%;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    min-width: calc(#{$cr-step-size} + (#{$cr-step-spacing} * 2));
    margin: calc(#{$cr-step-padd} * -1);

    .cr-step-wrapper {
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: $cr-step-padd;
    }

    .cr-icon {
      position: relative;
      z-index: 1;
      width: $cr-step-size;
      height: $cr-step-size;
      margin-bottom: 0.5rem;
      border-radius: 50%;
      background: $cr-step-bar-color;
      font-size: calc(#{$cr-step-size} * 0.4);
      line-height: $cr-step-size;
      text-align: center;
    }

    label {
      font-weight: normal;
    }

    em {
      color: $color-gray-600;
      font-style: normal;
      font-size: $font-size-sm;
    }

    .--clickable {
      cursor: pointer;

      label {
        cursor: pointer;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: calc((#{$cr-step-size} * 0.5) + #{$cr-step-padd});
      left: -50%;
      z-index: 0;
      width: 100%;
      height: $cr-step-bar-height;
      background: $cr-step-bar-color;
    }

    &:first-child {
      &:before {
        display: none;
      }
    }

    &.--status {
      &:after {
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 1;
        display: block;
        width: 20px;
        height: 20px;
        margin-top: calc(#{$cr-step-padd} * 0.3);
        margin-left: calc(#{$cr-step-size} * 0.2);
        border-radius: 50%;
        color: #fff;
        font-family: "Material Icons Outlined";
        line-height: 20px;
        text-align: center;
      }

      &.--checked {
        &:after {
          content: 'done';
          background: $app-color-success !important;
          font-size: $font-size-base;
        }
      }

      &.--error {
        &:after {
          content: 'priority_high';
          background: $app-color-warning !important;
          font-size: $font-size-sm;
        }
      }

      &.--busy {
        &:after {
          content: 'more_horiz';
          background: lighten($app-color-black, 15%) !important;
          font-size: $font-size-sm;
        }
      }
    }

    &.--activated {
      .cr-icon {
        background: $cr-step-bar-color-highlighted;
        color: #fff;
      }

      label {
        font-weight: bold;
      }

      &:before {
        background: $cr-step-bar-color-highlighted;
      } 
    }

    &.--highlighted {
      .cr-icon {
        background: $cr-step-bar-color-highlighted;
        color: #fff;

        &:before {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          display: block;
          width: calc(#{$cr-step-size} - 4px);
          height: calc(#{$cr-step-size} - 4px);
          margin-bottom: 0.5rem;
          border: 2px solid rgba(darken($color-primary, 30%), 0.4);
          border-radius: 50%;
          background: transparent;
        }
      }

      label {
        font-weight: bold;
      }
    }
  }
}