.al-dc {
  $app-builder-nav-width: 13rem;
  $app-builder-spacing: 1rem;
  $app-builder-padding: 1rem;
  $-ico-padd: 0.3rem;
  $-ico-size: calc((#{$-ico-padd} * 2) + #{$font-size-base});

  overflow-x: auto;
  max-width: 100%;
  padding-top: 1rem;

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .al-dc-wrapper {
    min-width: 40rem;

    &:after {
      content: "";
      display: block;
      clear: both;
    }

    .al-dc-nav {
      float: left;
      width: $app-builder-nav-width;
      //height: calc(100vh - #{$app-header-height});
      //overflow-y: auto;
    }

    .al-dc-nav-folders {
      .al-dc-nav-folder {
        margin-top: 1.5rem;

        &:first-child {
          margin-top: 0;
        }

        > .title {
          position: relative;
          display: block;
          padding-right: calc((#{$-ico-size} + #{$-ico-padd}) * 2);
          padding-left: $app-content-box-padding;
          font-size: $font-size-base;
          line-height: $font-size-base;

          > .folder {
            position: absolute;
            top: -$-ico-padd;
            right: calc((#{$-ico-padd} * -1 + #{$app-content-box-padding}));
            display: block;
            width: $-ico-size;
            height: $-ico-size;
            line-height: $-ico-size;
            cursor: pointer;

            &:after {
              content: "keyboard_arrow_right";
              display: block;
              width: 100%;
              height: 100%;
              font-family: "Material Icons Outlined";
              line-height: $-ico-size;
              text-align: center;
            }
          }
        }
      }
    }

    .al-dc-nav-categories {
      margin-top: 0.8rem;
      padding: $app-content-box-padding $app-content-box-padding calc(#{$app-content-box-padding} - 0.7rem) $app-content-box-padding;
      border: $app-content-box-border;
      border-radius: $app-content-box-radius;
      background: $app-content-box-background;
      color: $app-content-box-color;
      box-shadow: $app-content-box-shadow;

      .al-dc-nav-category {
        margin-top: 2rem;

        > .title {
          position: relative;
          display: block;
          font-size: $font-size-base;
          line-height: $font-size-base;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .al-dc-nav-sections {
      margin-top: 0.7rem;

      .al-dc-nav-section {
        position: relative;
        margin: 0;
        padding: 0;

        &:after {
          content: "";
          display: block;
          height: 1px;
          margin-left: 1rem;
          background: fade-out($app-text-color, 0.9);
        }

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }

        > .title {
          display: block;
          margin-right: 3rem;
          padding: 0.45rem 0 0.45rem 1rem;
          color: $app-text-color;
          font-size: $font-size-sm;
          line-height: 100%;
          line-height: calc(#{$font-size-sm} * 1.3);
          text-decoration: none;
          text-indent: -1rem;

          &:before {
            content: "subdirectory_arrow_right";
            display: inline-block;
            padding-right: 0.3rem;
            font-size: $font-size-sm;
            font-family: "Material Icons Outlined";
            text-indent: 0;
          }

          &:hover {
            color: $app-highlight-color;
          }
        }

        > .stats {
          position: absolute;
          top: 0.53rem;
          right: 0;

          > .stat {
            position: relative;
            display: inline-block;
            float: left;
            width: 0.75rem;
            height: 0.75rem;
            margin-left: 0.1rem;
            border-radius: 3px;
            background: red;
            font-size: 8px;
            font-family: verdana;
            line-height: 0.75rem;
            text-align: center;

            &:before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              display: block;
              border: 1px solid blue;
              border-radius: 3px;
            }

            &.sdv {
              background: transparent;
              &:before {
                border-color: $color-primary-light;
              }

              &.on {
                background: $color-primary-light;
                color: #ffffff;
              }
            }

            &.freeze {
              background: transparent;
              &:before {
                border-color: $app-color-warning;
              }

              &.on {
                background: $app-color-warning;
                color: #ffffff;
              }
            }

            &.lock {
              background: transparent;
              &:before {
                border-color: $app-color-danger;
              }

              &.on {
                background: $app-color-danger;
                color: #ffffff;
              }
            }
          }
        }
      }
    }

    .al-dc-cont {
      position: relative;
      margin-left: calc(#{$app-builder-nav-width} + #{$app-builder-spacing});
    }

    .al-dc-forms {
      max-width: 50rem;

      .al-dc-form {
        margin-top: 3rem;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .al-dc-field {
      margin-top: 0.8rem;
      padding: $app-content-box-padding;
      border: $app-content-box-border;
      border-radius: $app-content-box-radius;
      background: $app-content-box-background;
      color: $app-content-box-color;
      box-shadow: $app-content-box-shadow;

      > .description {
        position: relative;
        margin: 0 0 1rem 0;
        padding: 1rem 1rem 1rem 2.7rem;
        background: darken($app-content-box-background, 5);
        line-height: 140%;

        &:before {
          content: "emoji_objects";
          position: absolute;
          top: 0.85rem;
          left: 1rem;
          display: inline-block;
          color: $app-color-danger;
          font-size: $font-size-2xl;
          font-family: "Material Icons Outlined";
        }
      }

      .al-dc-element {
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          margin: 1rem 0;
          background: fade-out($app-content-box-color, 0.95);
        }

        .al-input input {
          width: 100%;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}

.al-dc-element {
  .al-element {
    > .label {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
      line-height: calc(#{$font-size-base} * 1.35);
    }

    .al-form {
      position: relative;
    }

    &.al-element-style-short {
      .al-input {
        max-width: 13rem;
      }
    }

    &.al-element-style-default {
      .al-input {
        max-width: 90%;
      }
    }

    &.al-element-style-date {
      .al-input {
        position: relative;
        max-width: 10rem;

        &:after {
          content: "calendar_today";
          position: absolute;
          top: 50%;
          right: 0.5rem;
          z-index: 2;
          display: block;
          width: 1.6rem;
          height: 1.6rem;
          margin-top: -0.8rem;
          font-size: $font-size-base;
          font-family: "Material Icons Outlined";
          line-height: 1.6rem;
          text-align: right;
        }
      }
    }
  }

  .al-element-input {
  }
  .al-element-radio {
    .al-element-radio-cont {
      width: 100%;

      .al-element-radio-label {
        vertical-align: middle;
        width: 10px;
        padding: 0.2rem 0;
        white-space: nowrap;

        .al-radio {
          margin: 0;

          > label {
            padding-right: 2rem;
          }
        }
      }

      .al-element-radio-add {
        vertical-align: middle;
        padding: 0.2rem 0;

        .description {
          margin: 0;
        }
      }
    }
  }
  .al-element-rating {
  }
  .al-element-signature {
  }
}
