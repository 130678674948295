.cr-clearable-radio-buttons {
  &.direction--horizontal {
    .cr-clearable-radio-button-wrapper {
      margin-top: 0;
    }
  }

  .cr-clearable-radio-button-wrapper {
    .cr-clearable-radio-button-link-wrapper {
      display: flex;
      gap: 2px;
    }
    &:not(.--display-block) {
      display: flex;

      .ms-Checkbox {
        display: inline-block;
      }

      .cr-clearable-radio-button-link-wrapper {
        flex-direction: row;
        align-items: center;
      }
    }

    &.--display-block {
      .ms-Checkbox-text {
        display: block;
      }

      .cr-clearable-radio-button-link-wrapper {
        flex-direction: column;
      }

      em {
        margin-top: 0.2rem;
      }
    }
  }

  .cr-clearable-radio-button {
    > div {
      margin-left: 4px;
    }

    .ms-Checkbox-text {
      margin-left: 0;
    }

    em {
      font-style: normal;
      font-size: $font-size-base;
      pointer-events: none;
    }
  }

  .is-disabled {
    .--linkable-descriptions a {
      color: #a19f9d;

      &:hover {
        color: darken(#a19f9d, 10%);
      }
    }

    .cr-clearable-radio-button {
      em {
        color: #a19f9d;
      }
    }
  }

  .ms-Checkbox-checkbox {
    left: 2px;
    width: 14px;
    height: 14px;
    margin-top: 2px;
    margin-bottom: 2px;
    border-color: #ccc;
    border-radius: 50%;
  }

  .ms-Checkbox > input:focus + .ms-Checkbox-label {
    &:before {
      outline: none;
    }
  }

  .ms-Checkbox:hover {
    .ms-Checkbox-checkbox {
      border-color: $color-primary;
      box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.4);
    }
  }

  .ms-Checkbox-text {
    padding-right: 4px;
    font-size: $font-size-base;
  }

  .ms-Checkbox-label {
    display: flex;
    flex-basis: 100%;
    align-items: flex-start;
    width: 100%;

    &:hover {
      &:before {
        content: "";
        width: 10px;
        height: 10px;
        margin: 4px 0 0 4px;
        border-color: #777 !important;
        border-radius: 100%;
        background-color: #777;
        transition-property: background-color;
      }
    }
  }

  .is-disabled {
    .ms-Checkbox {
      &:hover {
        .ms-Checkbox-checkbox {
          border-color: #aaa;
        }
      }
    }

    .ms-Checkbox-label {
      &:hover {
        &:before {
          border-color: #aaa !important;
          background-color: #aaa;
        }
      }
    }
  }

  .is-checked {
    &.is-disabled {
      .ms-Checkbox-checkbox {
        border-color: #ccc;
        :after {
          background-color: #ccc;
        }
      }

      .ms-Checkbox-text {
        color: #a19f9d;
      }
    }
    .ms-Checkbox-checkbox {
      border-color: $color-primary;
      background-color: white;

      :after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        margin-top: -5px;
        margin-left: -5px;
        border-color: $color-primary;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
        background-color: $color-primary;
      }
    }
    .ms-Checkbox-text {
      color: $color-gray-900;
      font-weight: 500;
    }
  }

  &.direction--horizontal {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    align-items: center;
    margin-left: -0.2rem;
    .ms-Checkbox {
      margin: 0 0.2rem 0 0.6rem;
    }
  }

  &.direction--vertical {
    display: flex;
    flex-direction: column !important;
    .cr-clearable-radio-button.--full {
      width: 100%;
    }
    .--type-text {
      margin-left: 6px !important;
      padding: 0.2rem 0 0 0;
    }
  }

  &.align--center {
    justify-content: center;
  }

  &.align--left {
    align-items: flex-start !important;
  }

  .cr-clearable-radio-buttons-img {
    display: flex;

    &.position--top {
      flex-direction: column-reverse;
      .ms-Checkbox-label {
        margin-top: 5px;
      }
      .--img {
        margin-left: 23px;
      }
    }
    &.position--right {
      .ms-Checkbox-label {
        width: 70px;
        .ms-Checkbox-text {
          word-break: break-word;
          max-width: 80px;
        }
      }
    }
    &.position--bottom {
      flex-direction: column;
      .ms-Checkbox-label {
        margin-bottom: 5px;
      }
      .--img {
        margin-left: 23px;
      }
    }

    @media screen and (-webkit-min-device-pixel-ratio: 2) {
      .--img {
        max-width: 110px;
        max-height: 120px;
      }
    }

    .--img {
      max-width: 110px;
      max-height: 120px;
    }
  }
}
