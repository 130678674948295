.cr-dropdown {
  .ms-Dropdown,
  .ms-Dropdown-title {
    border-color: #ccc;
    border-radius: 0;
    outline: none;

    &:hover {
      border-color: #aaa;
    }

    &:active {
      border-color: #bbb;
    }

    &:focus:after {
      display: none;
    }
  }

  .ms-Label {
    font-size: $font-size-sm;
  }

  .ms-Dropdown:hover {
    .ms-Dropdown-title {
      border-color: #aaa;
    }
  }

  .ms-Dropdown.is-open,
  .ms-Dropdown:active {
    .ms-Dropdown-title {
      border-color: $color-primary;
      outline: 1px solid $color-primary;
    }
  }

  .ms-Dropdown.is-disabled .ms-Dropdown-title {
    border: 1px solid #ccc;
    background: #f0f0f0;
  }
}

.cr-dropdown-callout {
  &.--unselectable {
    .ms-Dropdown-items {
      button:first-child {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .ms-Callout-main {
    max-height: 250px !important;
  }

  .ms-Button {
    height: initial;
  }
}