@import "variables.scss";

.app-header-wrapper {
  $-clickable-region: 0.8rem;
  min-width: 450px;

  height: $app-header-height;
  background: $app-header-background;
  box-shadow: $app-header-shadow;
  line-height: $app-header-height;
  user-select: none;

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  > .header {
    float: left;
    color: $app-header-color;
    font-weight: $font-weight-bold;
    font-size: $font-size-lg;
  }

  > .navi {
    $-padding: 0;
    float: right;

    ul,
    li {
      margin: 0;
      padding: 0;
    }

    .uat-button {
      position: absolute;
      top: 5.25rem;
      right: 1.75rem;
      height: 36px;
      border: none;
      border-radius: 20px;
      background: #ff8f00;
      color: #fff;
      box-shadow: $app-header-shadow;
    }

    .navi-wrapper {
      margin-right: $app-content-padding;

      @media (max-width: 600px) {
        margin-right: calc(#{$app-content-padding} * 0.5);
      }

      &:after {
        content: "";
        display: block;
        clear: both;
      }

      .nav {
        display: inline-block;
        float: left;
        height: $app-header-height;
        padding: 0;
        color: $app-header-color;
        font-size: $font-size-sm;
        line-height: $app-header-height;
        white-space: nowrap;

        @media (max-width: 650px) {
          &:has(.timer) {
            display: none;
          }
        }

        @media (max-width: 500px) {
          &.help {
            display: none;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        > a,
        > button {
          height: $app-header-height;
          border: none;
          background: transparent;
          outline: none;
          line-height: $app-header-height;
        }

        > a,
        > button,
        .cr-contextual-menu,
        .cr-contextual-menu i {
          color: $app-header-color;
          font-weight: $font-weight-normal;
          font-size: $font-size-base;
          -webkit-font-smoothing: antialiased;
        }

        .cr-contextual-menu {
          height: $app-header-height;
          line-height: $app-header-height;

          .ms-Button-flexContainer {
            height: $app-header-height;
            line-height: $app-header-height;
          }

          .material-icons-outlined {
            margin-right: 0.3rem;
          }
        }

        .cr-contextual-menu + .ms-Callout-container .ms-Callout {
          margin-top: -1rem;
        }

        .timer {
          margin-left: 0.55rem;
          text-align: center;
          text-decoration: none;
          cursor: pointer;

          &:before {
            content: "access_time";
            display: inline-block;
            vertical-align: middle;
            margin-top: -0.1rem;
            margin-right: 0.3rem;
            font-size: 1rem;
            font-family: "Material Icons Outlined";
          }

          &.--busy {
            opacity: 0.5;
            transition: opacity ease-in-out 0.2s;
          }

          &.--disabled {
            cursor: default;
          }
        }

        &.profile {
          margin-left: 0.55rem;

          span i:first-child {
            display: none;
          }

          .user-name-or-email {
            overflow: hidden;
            max-width: 150px;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &.help {
          position: relative;

          i {
            padding: 0 1rem;
            font-size: 1.2rem;

            @media (max-width: 550px) {
              padding: 0 0.5rem;
            }
          }

          &:after {
            content: "";
            position: absolute;
            top: 35%;
            right: 0;
            bottom: 35%;
            width: 1px;
            background: lighten($app-header-background, 40%);
            opacity: 0.5;
          }

          &.--no-user:after {
            display: none;
          }
        }

        &.change-language {
          position: relative;
          margin: 0;

          @media (max-width: 720px) {
            .ms-Button-flexContainer {
              padding: 0 0;
              text-indent: -9999px;

              i:first-child {
                text-indent: 0;
              }

              i:last-child {
                display: none;
              }
            }
          }

          @media (max-width: 550px) {
            .ms-Button {
              margin: 1px 0 0 0;
              padding: 0;

              i {
                margin: 0 0.5rem !important;
                padding: 0;
              }
            }
          }

          .cr-contextual-menu i {
            margin-right: 0.5rem;
            margin-left: 0.5rem;
          }

          .cr-contextual-menu i:first-child {
            margin-right: 0.3rem;
            font-size: 1.2rem;
          }

          .ms-ContextualMenu {
            min-width: 150px;
          }

          &:after {
            content: "";
            position: absolute;
            top: 35%;
            right: 0;
            bottom: 35%;
            width: 1px;
            background: lighten($app-header-background, 40%);
            opacity: 0.5;
          }
        }

        &.dropdown {
          > span {
            display: block !important;
            height: $app-header-height;
            line-height: $app-header-height;
          }

          button > span {
            position: relative;
            overflow: hidden;
            max-width: 200px;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

  &:after {
    clear: both;
  }
}
