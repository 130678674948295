.al-builder {
  $app-builder-nav-width: 13rem;
  $app-builder-spacing: 1rem;
  $app-builder-padding: 1rem;
  $-ico-padd: 0.3rem;
  $-ico-size: calc((#{$-ico-padd} * 2) + #{$font-size-base});

  overflow-x: auto;

  max-width: 100%;

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .al-dropdown.menu {
    .al-dropdown-toggle {
      width: $-ico-size;
      height: $-ico-size;
      margin: 0;
      padding: 0;
      border: none;
      border-radius: 0;
      background: transparent;
      text-indent: -9999px;

      &:active {
        background: transparent !important;
      }

      &:after {
        content: "reorder";
        position: absolute;
        top: 0;
        right: auto;
        left: 0;
        width: $-ico-size;
        height: $-ico-size;
        margin: 0;
        padding: 0;
        font-size: $font-size-base;
        line-height: $-ico-size;
        text-align: center;
        text-indent: 0;
        transform: none !important;
      }
    }

    .al-dropdown-menu {
      right: -0.15rem;
      left: auto;
      width: calc(#{$app-builder-nav-width} * 0.85);
      max-width: none;
      transform: translate3d(0.7rem, 1.5rem, 0) !important;

      &.show {
        transform: translate3d(0.7rem, 2rem, 0) !important;
      }

      &:before {
        right: 1.15rem;
      }
    }
  }

  .al-builder-wrapper {
    min-width: 40rem;

    &:after {
      content: "";
      display: block;
      clear: both;
    }

    .al-builder-nav {
      float: left;
      width: $app-builder-nav-width;
    }

    .al-builder-nav-folder-add {
      display: block;
      width: 100%;
      margin-bottom: 1.5rem;
    }

    .al-builder-nav-folders {
      .al-builder-nav-folder {
        margin-top: 1.5rem;

        &:first-child {
          margin-top: 0;
        }

        > .title {
          position: relative;
          display: block;
          padding-right: calc((#{$-ico-size} + #{$-ico-padd}) * 3);
          padding-left: $app-content-box-padding;
          font-size: $font-size-base;
          line-height: $font-size-base;

          > .menu,
          > .folder {
            position: absolute;
            top: -$-ico-padd;
            right: calc((#{$-ico-padd} * -1 + #{$app-content-box-padding}));
            display: block;
            width: $-ico-size;
            height: $-ico-size;
            line-height: $-ico-size;
            cursor: pointer;

            &:after {
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              font-family: "Material Icons Outlined";
              line-height: $-ico-size;
              text-align: center;
            }
          }

          > .folder {
            margin-right: $-ico-size;

            &:after {
              content: "keyboard_arrow_right";
            }
          }
        }
      }
    }

    .al-builder-nav-categories {
      margin-top: 0.8rem;
      padding: $app-content-box-padding;
      border: $app-content-box-border;
      border-radius: $app-content-box-radius;
      background: $app-content-box-background;
      color: $app-content-box-color;
      box-shadow: $app-content-box-shadow;

      .al-builder-nav-category {
        margin-top: 2rem;
        > .title {
          position: relative;
          display: block;
          font-size: $font-size-base;
          line-height: $font-size-base;

          > .menu {
            position: absolute;
            top: -$-ico-padd;
            right: -$-ico-padd;
            display: block;
            width: $-ico-size;
            height: $-ico-size;
            line-height: $-ico-size;
            cursor: pointer;

            &:after {
              content: "reorder";
              display: block;
              width: 100%;
              height: 100%;
              font-family: "Material Icons Outlined";
              line-height: $-ico-size;
              text-align: center;
            }
          }
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .al-builder-nav-sub-categories {
      margin-top: 1rem;

      .al-builder-nav-sub-category {
        position: relative;
        margin-top: 0.5rem;
        padding: 0;

        &:first-child {
          margin-top: 0;
        }

        > .title {
          display: block;
          padding: 0.4rem;
          border: 1px dashed fade-out(darken($app-content-background, 30%), 0.1);
          color: $app-text-color;
          font-size: $font-size-sm;
          line-height: 100%;
          text-decoration: none;

          > span {
            display: none;
          }

          &:hover {
            border-color: fade-out(darken($app-content-background, 65%), 0.1);
            color: $app-highlight-color;
          }
        }
      }
    }

    .al-builder-cont {
      position: relative;
      margin-left: calc(#{$app-builder-nav-width} + #{$app-builder-spacing});
    }

    .al-builder-form-add {
      width: 100%;
      margin-bottom: 1.5rem;
      padding-right: 0;
      padding-left: 0;
    }

    .al-builder-forms {
      .al-builder-form {
        margin-top: 3rem;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .al-builder-fields {
      margin-top: 1rem;
      padding: $app-content-box-padding;
      border: $app-content-box-border;
      border-radius: $app-content-box-radius;
      background: $app-content-box-background;
      color: $app-content-box-color;
      box-shadow: $app-content-box-shadow;

      .al-builder-field {
        margin-top: 1rem;

        .al-input input {
          max-width: 10rem;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
