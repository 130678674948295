.hb-horizontal-select-list {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 20px;
  padding: 0;
  user-select: none;
}
.hb-horizontal-select-item {
  position: relative;
  display: inline-block;
  list-style-type: none;
  background: $color-gray-100;
  height: 60px;
  text-align: center;
  user-select: none;
  > input {
    width: 0;
    height: 0;
  }
  > label {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    word-break: keep-all;
    word-wrap: break-word;

    > span {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 15px;
    }
  }
}
.hb-horizontal-select-item.--selected {
  border: 1px solid $color-gray-900;
}
.hb-horizontal-select-description {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;

  > div {
    display: inline-block;
    width: 100%;

    &.--half {
      width: calc(100% / 2);
    }
    &.--thirds {
      width: calc(100% / 3);
    }
  }
  > .--start {
    text-align: left;
  }
  > .--middle {
    display: flex;
    text-align: center;
    flex-flow: column-reverse;
  }
  > .--end {
    text-align: right;
  }
}
