$-spacing: 1.2rem;
$-gap: 0.5rem;
$-min-size: 100px;
$-min-width: 300px;

.cr-description {
  .cr-description-row {
    display: flex;
    flex-flow: row nowrap;
    box-sizing: border-box;
    margin-top: $-gap;

    &:first-child {
      margin-top: 0;
    }

    .cr-description-item {
      display: flex;
      flex-basis: 100%;
      box-sizing: border-box;
      margin-left: $-spacing;

      &.--short {
        max-width: 250px;
      }

      &:first-child {
        margin-left: 0;
      }

      .cr-description-label,
      .cr-description-desc {
        line-height: 145%;

        &.--ellipsis {
          width: 130px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }
      }

      .cr-description-label {
        display: inline-table;
        min-width: 100px;
        font-weight: bold;

        &.--long {
          min-width: 160px;
        }
      }
    }
  }
}

.cr-description {
  @mixin _wrap() {
    flex-flow: wrap;

    .cr-description-item {
      margin-top: $-gap;
      margin-left: 0;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  @mixin _wrap_block() {
    .cr-description-item {
      display: block;
    }
  }

  @mixin _wrap_flex() {
    .cr-description-item {
      display: flex;
    }
  }

  // NOTE: 화면 사이즈별로 `.--len-x` 클래스에 _wrap()
  @media (max-width: calc(#{$-min-size} + (#{$-min-width} * 2))) {
    .cr-description-row.--len-2 {
      @include _wrap();
    }
  }
  @media (max-width: calc(#{$-min-size} + (#{$-min-width} * 3))) {
    .cr-description-row.--len-3 {
      @include _wrap();
    }
  }
  @media (max-width: calc(#{$-min-size} + (#{$-min-width} * 4))) {
    .cr-description-row.--len-4 {
      @include _wrap();
    }
  }
  @media (max-width: calc(#{$-min-size} + (#{$-min-width} * 5))) {
    .cr-description-row.--len-5 {
      @include _wrap();
    }
  }

  // NOTE: 특정 사이즈에서 분기하여 _wrap_block() 또는 _wrap_flex()를 다르게 적용
  @media (max-width: calc(#{$-min-size} + (#{$-min-width} * 5))) {
    .cr-description-row {
      @include _wrap_block();
    }
  }
  @media (min-width: calc(#{$-min-size} + (#{$-min-width} * 5))) {
    .cr-description-row {
      @include _wrap_flex();
    }
  }
}
