.al-table {
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border: $app-content-box-border;
  border-radius: $app-content-box-radius;
  background: $app-content-box-background;
  color: $app-content-box-color;
  box-shadow: $app-content-box-shadow;

  .al-table-wrap {
    min-width: 100%;
    table-layout: fixed;

    .al-table-head {
      .al-table-col {
        padding: $app-table-head-padding;
        border-bottom: 2px solid $app-content-background;
        background: $app-content-box-background;
        font-weight: bold;
        font-size: $font-size-sm;
        text-transform: uppercase;
      }
    }

    .al-table-row {
      @include transition(0.1s);
      background: $app-content-box-background;

      &:nth-child(2n) {
        background: darken($app-content-box-background, 1.2%);
      }

      &:hover {
        background: darken($app-content-box-background, 3%);
      }
    }

    .al-table-col {
      padding: $app-table-cell-padding;

      > div {
        overflow: hidden;
        max-width: 600px;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media (max-width: 2500px) {
          max-width: 500px;
        }

        @media (max-width: 2000px) {
          max-width: 450px;
        }

        @media (max-width: 1500px) {
          max-width: 400px;
        }

        @media (max-width: 1000px) {
          max-width: 300px;
        }

        @media (max-width: 500px) {
          max-width: 250px;
        }

        .al-button {
          margin-right: 0.07rem;
          margin-left: 0.07rem;
          text-transform: uppercase;
        }
      }

      &:first-child {
        padding-left: 1.2rem;
      }

      &:last-child {
        padding-right: 1.2rem;
      }
    }
  }
}

.al-table-col {
  &.--text {
    min-width: 50px;
  }

  &.--text-long {
    min-width: 145px;

    &.--fixed {
      width: 145px;
    }
  }

  &.--text-mid {
    min-width: 110px;

    &.--fixed {
      width: 110px;
    }
  }

  &.--text-short {
    min-width: 90px;

    &.--fixed {
      width: 90px;
    }
  }

  &.--text-tiny {
    min-width: 75px;

    &.--fixed {
      width: 75px;
    }
  }

  &.--number-long {
    min-width: 130px;

    &.--fixed {
      width: 130px;
    }
  }

  &.--number-mid {
    min-width: 100px;

    &.--fixed {
      width: 100px;
    }
  }

  &.--number-short {
    min-width: 75px;

    &.--fixed {
      width: 75px;
    }
  }

  &.--number-tiny {
    min-width: 60px;

    &.--fixed {
      width: 60px;
    }
  }

  &.--datetime-secs {
    min-width: 180px;

    &.--fixed {
      width: 180px;
    }
  }

  &.--button-single {
    width: 120px;
    min-width: 100px;

    > div {
      text-align: center;
    }
  }

  &.--button-double {
    width: 230px;
    min-width: 200px;

    > div {
      text-align: center;
    }
  }
}
