.cr-screen-capture {
  overflow-x: hidden;
  padding: 0;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: crosshair;
  }

  .overlay.highlighting {
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.5);
    background: none;
  }

  .overlay,
  .overlay:before,
  .overlay:after {
    box-sizing: border-box;
  }
}
