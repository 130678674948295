.maven-portal--member-invitation {
  display: flex;
  flex-direction: column;
  align-items: center;

  .cr-section-inner-title {
    text-align: center;
  }

  .cr-section-inner {
    flex-basis: 100%;
    width: 100%;
    max-width: 650px;
  }

  &:before {
    content: "drafts";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    margin: 0 auto 30px auto;
    padding: 1rem;
    border-radius: 50rem;
    background: #ffffff;
    box-shadow: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.03);
    font-weight: normal;
    font-size: 70px;
    font-family: "Material Icons Outlined";
    text-indent: 0;
  }
}
