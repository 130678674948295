.cr-sortable-list {
  .cr-sortable-list-header,
  .cr-sortable-list-item {
    margin: 0.4rem 0;
    padding: $app-content-box-padding-narrow;
  }

  .cr-sortable-list-header,
  .cr-sortable-list-item .cr-sortable-list-item-body {
    display: flex;
    flex-basis: 100%;
    flex-direction: row;
  }

  .cr-sortable-list-header {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 0;
    transition: all 0.5s;

    &.--swap-disable {
      margin-left: -2.2rem;
    }
  }

  .cr-sortable-list-item-footer {
    .cr-sortable-list-header {
      margin: 0.4rem 0;
    }

    .cr-sortable-list-item {
      border: 1px solid rgba(#d9d9d9, 0.5);

      .cr-sortable-list-item-footer {
        .cr-sortable-list-item {
          border: 1px solid rgba(#d9d9d9, 0.7);
        }
      }
    }

    .cr-sortable-list {
    }
  }

  .cr-sortable-list-header-column {
    font-weight: bold;
    font-size: $font-size-sm;
    text-align: center;

    &.--required::after {
      content: "*";
      margin-left: 0.2rem;
      color: rgb(164, 38, 44);
    }
  }

  .cr-sortable-list-header-column,
  .cr-sortable-list-item-column {
    flex-basis: 100%;
    width: 50%;
    margin: 0 0.1rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .cr-sortable-list-items {
  }

  .cr-sortable-list-item {
    border-radius: $app-form-border-radius;
    background: $app-form-background;
    box-shadow: $app-content-box-shadow;

    &:last-child {
      margin-bottom: 0;
    }

    &.--overlay {
      box-shadow: 0 0 0.9rem rgba(0, 0, 0, 0.2);
    }
  }

  .cr-sortable-list-header-column-draggable,
  .cr-sortable-list-header-column-remove,
  .cr-sortable-list-item-column-draggable,
  .cr-sortable-list-item-column-remove {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 40px;
    user-select: none;
    margin-left: 0;
    visibility: visible;
    transition: all ease 0.5s;
    opacity: 1;

    &.--swap-disable {
      margin-left: -2.2rem;
      visibility: hidden;
      opacity: 0;
      transition: all ease 0.5s;
    }
  }

  .cr-sortable-list-item-column-draggable {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .cr-sortable-list-header-column-remove,
  .cr-sortable-list-item-column-remove {
    max-width: 30px;
    font-size: $font-size-xs;
  }

  .cr-sortable-list-item-column-remove {
    border: 1px solid #eee;
    border-radius: 50%;
    background: #eee;
    transform: scale(0.6);

    &:hover {
      border: 1px solid #cfcfcf;
      background: #e0e0e0;
    }

    &.--not-removable {
      background: #eee;
      opacity: 0.4;
      cursor: default;

      &:hover {
        border: none;
        background: #eee;
      }
    }
  }

  .cr-sortable-list-item-column-remove {
    cursor: pointer;
  }
}
