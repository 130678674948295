.cr-cropper {
}

.cr-dialog--screen-capture {
  .ms-Dialog-title {
    overflow: hidden;
    height: 24px;
    min-height: auto;
    margin-bottom: 0;
    padding: 0;
    text-indent: -9999px;
  }

  .ms-Dialog-main {
    width: 85%;

    .ms-Modal-scrollableContent {
      overflow: hidden;
      height: 100%;
      max-height: 100%;

      > div {
        height: 100%;
      }

      .ms-Dialog-inner {
        height: 100%;

        .ms-Dialog-content {
          height: calc(100% - /* Button Top */ 25px - /* Button Height */ 24px - /* Header Height */24px);
        }
      }
    }
  }

  .cr-screen-capture-button-cropper {
  }
}

.cr-screen-capture--on {
  .uat-add-dialog {
    visibility: hidden;
  }

  .cr-dialog--screen-capture {
    visibility: hidden;
  }
}

.cropper-line {
  background-color: $color-primary !important;
}

.cropper-view-box {
  outline-color: $color-primary !important;
}

.cropper-point {
  background-color: $color-primary !important;
}