.al-title {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px dotted #ccc;

  .title {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: $font-size-xl;
  }

  .meta {
    padding-top: 3px;
    color: darken($app-form-border-color, 20%);
    font-size: $font-size-base;

    .meta-time-info {
      position: relative;
      top: 1px;
    }
    
    .meta-time-info:before{
      content: '';
      display: inline-block;
      vertical-align: top;
      width: 2px;
      height: 2px;
      margin: 6px 6px 0 4px;
      background-color: #aaa;
    }
  }
}

.al-content {
  margin-top: 2rem;
}

.al-footer {
  margin-top: 2rem;
  padding: 1rem 0 1rem 0;
  border-top: 1px dotted #ccc;

  .al-footer-title {
    padding-top: 3px;
    color: darken($app-form-border-color, 20%);
    font-size: $font-size-base;
  }

  .al-footer-content {
    display: flex;

    >span {
      display: flex;
      align-items: center;
    }
  }
}