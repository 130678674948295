.cr-pagination {
  margin-top: $app-content-box-padding;
  padding: 0.5rem 0;

  .cr-pagination-page-navi,
  .cr-pagination-page-current,
  .cr-pagination-page {
    min-width: 30px;
    height: 30px;
    margin: 0 0.1rem;
    padding: 0;
    border-radius: 3rem;
    color: $app-content-box-color;
    line-height: 30px;
  }

  .cr-pagination-page-navi {
    i {
      font-size: 12px;
    }
  }

  .cr-pagination-page-current,
  .cr-pagination-page {
    margin: 0 0.2rem;
    background-color: $app-content-background;

    span {
      justify-content: center;
    }
  }

  .cr-pagination-page {
    &:hover {
      background-color: darken($app-content-background, 3%);
    }
    &:active {
      background-color: darken($app-content-background, 6%);
    }
  }

  .cr-pagination-page-current {
    background-color: $app-highlight-color;
    color: $app-highlight-text-color;

    &:active {
      background-color: darken($app-highlight-color, 5%);
    }
  }
}
