.cr-page {
  margin: 1rem 0;

  &.--with-breadcrumbs {
    margin-top: 0;
  }

  .cr-page-header {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    font-weight: $font-weight-bold;
    font-size: $font-size-3xl;

    .cr-page-header-subtitle {
      margin-left: 0.5rem;
      color: lighten($app-content-box-color, 15%);
      font-weight: $font-weight-normal;
      font-size: $font-size-lg;
    }
  }

  .cr-page-breadcrumbs {
    margin-top: 0;
    margin-bottom: 1.5rem;

    .ms-Breadcrumb-item,
    .ms-Breadcrumb-itemLink {
      font-size: $font-size-xs;
    }

    .ms-Breadcrumb-listItem:first-child {
      .ms-Breadcrumb-item,
      .ms-Breadcrumb-itemLink {
        padding-left: 2px;
      }
    }
  }

  .cr-page-inner {
  }
}
