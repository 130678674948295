.al-chart {
  position: relative;
  overflow: hidden;

  &:hover {
    overflow: visible;
  }

  // Line
  &.al-line-chart {
    .al-chart-wrapper {
      min-height: 170px;
      padding: 0.3rem 0.7rem;
    }

    .x-axis {
      display: flex;
      margin-top: -0.3rem;

      > * {
        flex-grow: 1;
        font-style: normal;
        font-size: $font-size-sm;
        text-align: center;

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  // Pie
  &.al-pie-chart {
    position: relative;
    min-height: 170px;

    .al-chart-wrapper {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 170px !important;
      margin-top: -85px;
    }
  }

  // ScatterPlot
  &.al-scatter-plot-chart {
    position: relative;
    min-height: 170px;

    .al-chart-wrapper {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 170px !important;
      margin-top: -85px;
    }
  }
}
