@import "variables.scss";

@import "component.scss";
@import "layout.app.scss";
@import "layout.app.hybrid.scss";
@import "layout.app.builder.scss";
@import "layout.app.ecoa.scss";
@import "layout.app.docs.scss";
@import "layout.app.auth.scss";
@import "layout.app.password.scss";
@import "layout.app.header.scss";
@import "layout.app.sidebar.scss";
@import "layout.app.drawer.scss";
@import "layout.app.ui.scss";
@import "layout.app.demo.scss";
@import "theme.secondary";

html.full,
html.full body {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
