.hb-progress-bar {
  $hb-progress-bar-height: 15px;
  $hb-progress-bar-indicator-height: 10px;

  position: relative;
  display: block;
  height: $hb-progress-bar-height;
  background: lighten($app-color-warning, 20%);

  > i {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    min-width: 5px;
    background: darken($app-color-danger, 5%);

    > strong {
      position: absolute;
      top: calc((#{$hb-progress-bar-indicator-height} * -1) - 7px);
      right: -4px;
      height: $hb-progress-bar-indicator-height;
      color: #fff;
      font-style: normal;
      font-size: $font-size-xs;
      line-height: $hb-progress-bar-indicator-height;
      text-align: left;

      span {
        position: relative;
        padding: 1px 5px;
        border-radius: 11px;
        background: darken($app-color-danger, 5%);
        white-space: nowrap;

        &:before {
          content: '';
          position: absolute;
          right: 2px;
          bottom: -1px;
          width: 0;
          height: 0;
          border-top: 5px solid darken($app-color-danger, 5%);
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
          transform: rotate(90deg);
        }
      }
    }
  }

  &.--under-10p {
    i strong {
      right: auto;
      
      span {
        &:before {
          right: auto;
          left: 0;
          transform: rotate(-90deg);
        }
      }}
  }
}